import axios from "axios";
import { RESET_PASSWORD_USER, USER_LIST } from "../../constant/API constant";

export const UserList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${USER_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const resetPassword = ({ formData, userId }) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${RESET_PASSWORD_USER}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { userId },
    }
  );
};
