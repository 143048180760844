import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext } from "react";
import { Edit, Plus } from "react-feather";
import {
  CardHeader,
  Card,
  Col,
  Row,
  Table,
  Form,
  Input,
  Button,
} from "reactstrap";
import {
  MuiTabelStyle,
  ORDER_LIST,
  QR_GUNRATE_STATUS,
  USER_ROLE,
} from "../../constant";
import OrderListContext from "../../context/orderListContext/OrderListContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";
const OrderListItem = (props) => {
  const {
    moment,
    sorting,
    handleRegenerate,
    handleEdit,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
  } = props;

  const {
    qrValue,
    setQrModal,
    setFilter,
    filter,
    dateFilter,
    setDateFilter,
    expiredFilter,
    serExpiredFilter,
    statusFilter,
    setStatusFilter,
  } = useContext(OrderListContext);

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "title",
      headerName: "CUSTOMER NAME",
      renderCell: (params) => (
        <>
          <span>{params.row.title}</span>
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() => handleEdit(params.row.qrOrderId)}
          >
            <Edit style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "qty",
      headerName: "QTY",
    },
    USER_ROLE.ADMIN === +localStorage.getItem("roleId")
      ? {
          flex: 0.1,
          minWidth: 170,
          field: "status",
          sortable: false,
          headerName: "STATUS",
          renderCell: (params) =>
            params.row.status === QR_GUNRATE_STATUS.CREATED &&
            params.row.status === QR_GUNRATE_STATUS.FAILED ? (
              <i class="fa fa-circle font-danger f-12"></i>
            ) : (
              <i class="fa fa-circle font-success f-12"></i>
            ),
        }
      : {
          flex: 0.1,
          minWidth: 170,
          sortable: false,
          field: "status",
          headerName: "STATUS",
          renderCell: (params) =>
            params.row.status === QR_GUNRATE_STATUS.CREATED ||
            params.row.status === QR_GUNRATE_STATUS.FAILED ? (
              <button
                className="btn btn-danger"
                onClick={() => handleRegenerate(params.row.qrOrderId)}
              >
                {ORDER_LIST.REGENERATE}
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => handleRegenerate(params.row.qrOrderId)}
                disabled
              >
                {ORDER_LIST.GENERATE}
              </button>
            ),
        },
    {
      flex: 0.1,
      minWidth: 200,
      field: "orgName",
      headerName: "CREATED BY",
      renderCell: (params) => params.row.orgDetail.orgName,
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "createdAt",
      headerName: "CREATED AT",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:MM"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "expiredAt",
      headerName: "EXPIRED AT",
      renderCell: (params) =>
        moment(params.row.expiredAt).format("DD MMM, YYYY HH:MM"),
    },
  ];

  const handleResetFilters = () => {
    setFilter("");
    setDateFilter("");
    setStatusFilter("");
    serExpiredFilter({ expiredStartDate: "", expiredEndDate: "" });
  };

  const handleExpiredFilter = (e) => {
    const { name, value } = e.target;
    serExpiredFilter((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h5>{ORDER_LIST.PERENT}</h5>
              {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
                ""
              ) : (
                <button
                  type="button"
                  style={{ border: "none", background: "none" }}
                  onClick={() => setQrModal(true)}
                >
                  <div
                    style={{
                      width: "50px",
                      background: "#7366ff",
                      borderRadius: "19rem",
                      color: "white",
                    }}
                  >
                    <h1>
                      <Plus />
                    </h1>
                  </div>
                </button>
              )}
            </CardHeader>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Row>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Order List Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="date"
                            placeholder="Order List Search..."
                            value={dateFilter}
                            onInput={(e) => setDateFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <select
                            className="form-control"
                            onChange={(e) => setStatusFilter(e.target.value)}
                            value={statusFilter}
                            name="planName"
                          >
                            <option value="">--select--</option>
                            {Object.keys(QR_GUNRATE_STATUS).map((elem) => {
                              return (
                                <option value={QR_GUNRATE_STATUS[elem]}>
                                  {elem}
                                </option>
                              );
                            })}
                          </select>
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Button onClick={() => handleResetFilters()}>
                          Reset Filter
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <h6 className="mx-1 mt-2 mb-3">
                        Expired Filters (Start Date - End Date)
                      </h6>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="date"
                            name="expiredStartDate"
                            placeholder="Order List Search..."
                            value={expiredFilter.expiredStartDate}
                            onInput={(e) => handleExpiredFilter(e)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                      <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="date"
                            name="expiredEndDate"
                            placeholder="Order List Search..."
                            value={expiredFilter.expiredEndDate}
                            onInput={(e) => handleExpiredFilter(e)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col>
                    </Row>
                  </th>
                </tr>
              </thead>
            </Table>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
              }}
            >
              {qrValue.length && qrValue.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={qrValue}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.qrOrderId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderListItem;
