export const userLogout = (history, socket) => {
  localStorage.removeItem("profileURL");
  localStorage.removeItem("token");
  localStorage.removeItem("name");
  localStorage.removeItem("roleId");
  localStorage.removeItem("orgId");
  localStorage.removeItem("orgIdGenQr");
  socket?.disconnect();
  history(`/login`);
};
