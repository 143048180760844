import * as React from "react";
import { useState } from "react";
import OrderListContext from "./OrderListContext";

const OrderListState = (props) => {
  const [qrValue, setqrValue] = useState([]);

  const [qty, setQty] = useState("1");
  const [title, setTitle] = useState("");
  const [qrOrderId, setQrOrderId] = useState("");
  const [filter, setFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [expiredFilter, serExpiredFilter] = useState({
    expiredStartDate: "",
    expiredEndDate: "",
  });

  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->

  const [qrmodal, setQrModal] = useState(false); //ADD DATA MODEL -->
  const [progress, setProgress] = useState(0);
  const [editTitle, setEditTitle] = useState(false);

  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);

  const [planName, setPlanName] = useState("");
  const [planMasterId, setPlanMasterId] = useState("");
  const [planValidity, setPlanValidity] = useState("");
  const [planAmount, setPlanAmount] = useState("");
  const [note, setNote] = useState("");
  const [solutionIds, setSolutionIds] = useState([]);
  const [solutionDetails, setSolutionDetails] = useState([]);
  const [tests, setTests] = useState([]);

  return (
    <>
      <OrderListContext.Provider
        value={{
          qrValue,
          setqrValue,
          qty,
          setQty,
          title,
          setTitle,
          qrOrderId,
          setQrOrderId,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          totalRecord,
          setTotalRecord,
          isHovering,
          setIsHovering,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          qrmodal,
          setQrModal,
          progress,
          setProgress,
          editTitle,
          setEditTitle,
          isHovering1,
          setIsHovering1,
          isHovering2,
          setIsHovering2,
          isHovering3,
          setIsHovering3,
          filter,
          setFilter,

          planValidity,
          setPlanValidity,

          planAmount,
          setPlanAmount,

          planName,
          setPlanName,
          note,
          setNote,
          dateFilter,
          setDateFilter,
          expiredFilter,
          serExpiredFilter,
          statusFilter,
          setStatusFilter,
          planMasterId,
          setPlanMasterId,
          solutionDetails,
          setSolutionDetails,
          solutionIds,
          setSolutionIds,
          tests,
          setTests,
        }}
      >
        {props.children}
      </OrderListContext.Provider>
    </>
  );
};

export default OrderListState;
