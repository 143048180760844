import React, { useContext } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { Plus, Trash } from "react-feather";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { MuiTabelStyle, ORG_THEME_MASTER } from "../../constant";
import OrgThemeAccessContext from "../../context/OrgThemeAccessContext/OrgThemeAccessContext";
import Switch from "react-js-switch";

const OrgThemeAccessMasterItem = (props) => {
  const {
    moment,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    sorting,
    handleChangeStatus,
    deleteOrgTheme,
  } = props;

  const { packageValue, setModal, setFormData } = useContext(
    OrgThemeAccessContext
  );

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 230,
      field: "templateId",
      headerName: "Template Id",
      renderCell: (params) =>
        params.row.templateId ? params.row.templateId : "-",
    },
    {
      flex: 0.1,
      minWidth: 230,
      field: "templateName",
      headerName: "Theme Name",
      renderCell: (params) =>
        params.row.themeMasterDetail
          ? params.row.themeMasterDetail?.templateName
          : "-",
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "isActive",
      headerName: "Status",
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            handleChangeStatus(
              params.row.orgSellerAccessThemeId,
              params.row.isActive
            )
          }
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CREATED AT",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:mm"),
    },
    {
      sortable: false,
      field: "actions",
      headerName: "ACTIONS",
      renderCell: (params) => (
        <>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              // color: "#51bb25",
              color: "red",
            }}
            onClick={() => deleteOrgTheme(params.row.orgSellerAccessThemeId)}
          >
            <Trash style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];
  return (
    <>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h3>{ORG_THEME_MASTER.PERENT}</h3>
              <button
                type="button"
                style={{ border: "none", background: "none" }}
                onClick={() => {
                  setModal(true);
                  if (packageValue?.length > 0) {
                    setFormData((prev) => {
                      return {
                        templateIds: packageValue?.map((elem) => {
                          return {
                            value: elem.templateId,
                            label: elem.themeMasterDetail.templateName,
                          };
                        }),
                      };
                    });
                  }
                }}
              >
                <div
                  style={{
                    width: "50px",
                    background: "#7366ff",
                    borderRadius: "19rem",
                    color: "white",
                  }}
                >
                  <h1>
                    <Plus />
                  </h1>
                </div>
              </button>
            </CardHeader>
            <Box
              sx={{
                "& .even": {
                  backgroundColor: "#f1f1f1",
                },
                "& .odd": {
                  backgroundColor: "white",
                },
              }}
            >
              {packageValue.length && packageValue.length ? (
                <DataGrid
                  autoHeight
                  pagination
                  sx={MuiTabelStyle}
                  rows={packageValue}
                  rowCount={record}
                  pageSize={pageSize}
                  getRowId={(row) => row.templateId}
                  columns={defaultColumns}
                  page={page - 1}
                  rowsPerPageOptions={[7, 10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                  paginationMode="server"
                  disableSelectionOnClick
                  checkboxSelection={false}
                  sortingMode="server"
                  onSortModelChange={sorting}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              ) : (
                <DataNotFound />
              )}
            </Box>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrgThemeAccessMasterItem;
