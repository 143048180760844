import React, { Fragment, useEffect, useContext, useState } from "react";
import { Container } from "reactstrap";

import moment from "moment";
import { toast, Zoom } from "react-toastify";
import OrgThemeAccessMasterItem from "./orgThemeAccessMasterItem";
import OrgThemeAccessMasterAddModel from "./orgThemeAccessMasterAddModel";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import Breadcrumbs from "../../layout/breadcrumb";
import { ORG_THEME_MASTER } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { useNavigate, useParams } from "react-router";
import OrgThemeAccessContext from "../../context/OrgThemeAccessContext/OrgThemeAccessContext";
import {
  addOrgThemeAccess,
  deleteOrgThemeAccess,
  getOrgThemeAccess,
  getOrgThemeAccessDetails,
  statusChange,
  updateOrgThemeAccess,
} from "../../api/OrgThemeAccessMasterApi/OrgThemeAccessMasterApi";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";
const OrgThemeAccessMaster = () => {
  const {
    setPackageValue,
    packageValue,
    setformvalue,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    setPackageID,
    progress,
    setProgress,
    seteditIndex,
    setToggleSubmit,
    formData,
    setFormData,
  } = useContext(OrgThemeAccessContext);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const { orgId: orgSlug } = useParams();
  /**
   * packges get list function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        search: { sellerOrgId: orgSlug },
      };
      const { data } = await getOrgThemeAccess(paramsObj);
      if (data.status === 200) {
        setPackageValue(data.payload.data);
        if (data.payload.data?.length > 0) {
          setFormData((prev) => {
            return {
              templateIds: data.payload.data?.map((elem) => {
                return {
                  value: elem.templateId,
                  label: elem.themeMasterDetail.templateName,
                };
              }),
            };
          });
        }
        setRecord(data.pager.totalRecords);
      } else {
        setPackageValue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy]);

  /**
   * enable / disable function call
   */

  const handleChangeStatus = async (orgSellerAccessThemeId, status) => {
    let formData;
    formData = {
      orgSellerAccessThemeId,
      isActive: !status,
    };
    try {
      const { data } = await statusChange(formData);
      if (data.status === 200) {
        status
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * delete org theme access
   */

  const deleteOrgTheme = async (orgSellerAccessThemeId) => {
    let formData;
    formData = {
      orgSellerAccessThemeId,
    };
    try {
      const { data } = await deleteOrgThemeAccess(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * plush button click to oen popup function  call
   */

  const AddModel = () => {
    setModal(true);
    setToggleSubmit(true);
  };

  /**
   * edit package details function call
   */

  const editHandle = async (orgSellerAccessThemeId) => {
    setPackageID(orgSellerAccessThemeId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await getOrgThemeAccessDetails(orgSellerAccessThemeId);
      if (data.status === 200) {
        setToggleSubmit(false);
        seteditIndex(orgSellerAccessThemeId);
        setModal(true);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * empty feild function call
   */

  const emptyFeild = () => {
    setFormData({ templateIds: [] });
    setLoaderShow(false);
  };

  /**
   * create and update function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let newFormData;
    if (packageValue?.length > 0) {
      setLoaderShow(true);
      newFormData = {
        templateIds: formData?.templateIds?.map((elem) => {
          return {
            templateId: elem.value,
          };
        }),
        orgId: +orgSlug,
      };
      try {
        const { data } = await updateOrgThemeAccess(newFormData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(true);
      newFormData = {
        templateIds: formData?.templateIds?.map((elem) => {
          return {
            templateId: elem.value,
          };
        }),
        orgId: +orgSlug,
      };
      try {
        const { data } = await addOrgThemeAccess(newFormData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * sorting funvtion call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };
  return (
    <>
      <Fragment>
        <Breadcrumbs parent={ORG_THEME_MASTER.PERENT} />
        <Container fluid={true}>
          <TopLoadingBar progress={progress} setProgress={setProgress} />
          <OrgThemeAccessMasterAddModel
            onSubmit={onSubmit}
            closeModel={closeModel}
          />
          <OrgThemeAccessMasterItem
            moment={moment}
            editHandle={editHandle}
            sorting={sorting}
            AddModel={AddModel}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            page={page}
            record={record}
            handleChangeStatus={handleChangeStatus}
            deleteOrgTheme={deleteOrgTheme}
          />
        </Container>
      </Fragment>
    </>
  );
};

export default OrgThemeAccessMaster;
