import React, { useContext } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, ORGANIZATION, PACKAGE_TYPE } from "../../constant";
import OrgContext from "../../context/OrgContext/OrgContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const AddOrgBelModel = (props) => {
  const { addBelence } = props;
  const { addbel, setAddBel, qty, setQty, type, setType } =
    useContext(OrgContext);
  const { loaderShow } = useContext(LoaderContext);
  return (
    <Modal size="lg" isOpen={addbel} toggle={() => setAddBel(!addbel)}>
      <ModalHeader toggle={() => setAddBel(!addbel)}>
        {ORGANIZATION.ADD_BALANCE}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => addBelence(e)}
                >
                  <Row>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom4">
                        {ORGANIZATION.QTY}
                      </Label>
                      <Input
                        className="form-control"
                        name="qty"
                        id="validationCustom4"
                        type="number"
                        placeholder="QTY"
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom5">
                        {ORGANIZATION.TYPE}
                      </Label>
                      <select
                        className="form-select"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option>--Select--</option>
                        {PACKAGE_TYPE.map((ele, index) => {
                          return (
                            <option
                              defaultChecked
                              value={ele.value}
                              key={index}
                            >
                              {ele.name}
                            </option>
                          );
                        })}
                        {/* 
                        <option defaultChecked value="nfc">
                          {ORGANIZATION.NFC}
                        </option>
                        <option value="qr">{ORGANIZATION.QR}</option> */}
                      </select>
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        style={{ width: "15rem" }}
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SAVE}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default AddOrgBelModel;
