import React from "react";
import Routers from "./route";
import { Provider } from "react-redux";
import store from "./store";
// import { Auth0Provider } from "@auth0/auth0-react";
// import { auth0 } from "./data/config";
import UserState from "./context/UserState";
import NfcListState from "./context/nfcListContext/NfcListState";
import NfcOrderListState from "./context/nfcOrderListContext/NfcOrderListState";
import OrderListState from "./context/orderListContext/OrderListState";
import QrListAllState from "./context/QrListAllContext/QrListAllState";
import AdminDeshboardState from "./context/AdminDeshboardContext/AdminDeshboardState";
import OrderMasterState from "./context/OrderMasterContext/OrderMasterState";
import ShippingMasterState from "./context/ShippingMasterContext/ShippingMasterState";
import PackagesState from "./context/PackagesContext/PackagesState";
import OrgState from "./context/OrgContext/OrgState";
import PrivillageMasterState from "./context/PrivillageMasterContext/PrivillageMasterState";
import ModualManagerState from "./context/ModuleManagerContext/ModualManagerState";
// import LoaderResponse from "./Reuseable/Loader";
import LoaderState from "./context/LoaderContext/LoaderState";
import WhiteLabelDomainState from "./context/WhiteLabelDomainContext/WhiteLabelDomainState";
import WalletState from "./context/wallterContext/WalletState";
import LoaderResponse from "./Reuseable/Loader";
import SocketState from "./context/SocketContext/SocketState";
import OrgThemeAccessState from "./context/OrgThemeAccessContext/OrgThemeAccessState";

const App = () => (
  <div className="App">
    {/* <Auth0Provider
      domain={auth0.domain}
      clientId={auth0.clientId}
      redirectUri={auth0.redirectUri}
    > */}
    <Provider store={store}>
      <WhiteLabelDomainState>
        <ModualManagerState>
          <PrivillageMasterState>
            <SocketState>
              <LoaderState>
                <OrgThemeAccessState>
                  <WalletState>
                    <OrgState>
                      <PackagesState>
                        <ShippingMasterState>
                          <OrderMasterState>
                            <AdminDeshboardState>
                              <QrListAllState>
                                <NfcOrderListState>
                                  <OrderListState>
                                    <NfcListState>
                                      <UserState>
                                        <LoaderResponse />
                                        <Routers />
                                      </UserState>
                                    </NfcListState>
                                  </OrderListState>
                                </NfcOrderListState>
                              </QrListAllState>
                            </AdminDeshboardState>
                          </OrderMasterState>
                        </ShippingMasterState>
                      </PackagesState>
                    </OrgState>
                  </WalletState>
                </OrgThemeAccessState>
              </LoaderState>
            </SocketState>
          </PrivillageMasterState>
        </ModualManagerState>
      </WhiteLabelDomainState>
    </Provider>
    {/* </Auth0Provider> */}
  </div>
);

export default App;
