import React, { Fragment, useEffect, useContext, useState } from "react";
import { Container } from "reactstrap";

import moment from "moment";
import { toast, Zoom } from "react-toastify";
import PackagesItem from "./packagesItem";
import PackageAddModel from "./packageAddModel";
import {
  OrgList,
  PackageList,
  Package_Add,
  Package_Details,
  Package_Edit,
} from "../../api/Package Api/PackageApi";
import PackagesContext from "../../context/PackagesContext/PackagesContext";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import Breadcrumbs from "../../layout/breadcrumb";
import { PACKAGES } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { tableId } from "../../utils/helper";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";
const Package = () => {
  const {
    setPackageValue,
    setformvalue,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    name,
    setName,
    price,
    setPrice,
    gst,
    setGst,
    qty,
    setQty,
    type,
    setType,
    orgId,
    setOrgId,
    benefit,
    setBenefit,
    packageId,
    setPackageID,
    progress,
    setProgress,
    editIndex,
    seteditIndex,
    toggleSubmit,
    setToggleSubmit,
    formvalue,
  } = useContext(PackagesContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * packges get list function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await PackageList(paramsObj);
      if (data.status === 200) {
        setPackageValue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setPackageValue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * org list function call
   */

  const oprenModel = async () => {
    let paramsObj = {
      showAll: true,
    };
    try {
      const { data } = await OrgList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    oprenModel();
  }, []);

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy]);

  tableId(page, pageSize, formvalue);

  /**
   * plush button click to oen popup function  call
   */

  const AddModel = () => {
    setModal(true);
    setToggleSubmit(true);
  };

  /**
   * edit package details function call
   */

  const editHandle = async (packageId) => {
    setPackageID(packageId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Package_Details(packageId);
      if (data.status === 200) {
        setToggleSubmit(false);
        seteditIndex(packageId);
        setModal(true);

        setName(data.payload.data.name);
        setPrice(data.payload.data.price);
        setGst(data.payload.data.gst);
        setQty(data.payload.data.qty);
        setType(data.payload.data.type);
        setBenefit(data.payload.data.benefit);
        setOrgId(data.payload.data.orgId);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * empty feild function call
   */

  const emptyFeild = () => {
    setName("");
    setPrice("");
    setGst("");
    setQty("");
    setType("");
    setBenefit("");
    setOrgId("");
    setPackageID("");
    setLoaderShow(false);
  };

  /**
   * fill feild function call
   */

  const fillFeild = () => {
    setName(name);
    setPrice(price);
    setGst(gst);
    setQty(qty);
    setType(type);
    setBenefit(benefit);
    setOrgId(orgId);
    setPackageID(packageId);
    setLoaderShow(false);
  };

  /**
   * create and update function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if (editIndex > -1 && !toggleSubmit) {
      setLoaderShow(!loaderShow);
      formData = {
        name: name,
        price: price,
        gst: gst,
        qty: qty,
        type: type,
        orgId: orgId,
        packageId: packageId,
        benefit: benefit,
      };
      try {
        const { data } = await Package_Edit(formData, packageId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = {
        name: name,
        price: price,
        gst: gst,
        qty: qty,
        type: type,
        orgId: orgId,
        benefit: benefit,
      };
      try {
        const { data } = await Package_Add(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(!false);
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * sorting funvtion call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };
  return (
    <>
      <Fragment>
        <Breadcrumbs parent={PACKAGES.PERENT} />
        <Container fluid={true}>
          <TopLoadingBar progress={progress} setProgress={setProgress} />
          <PackageAddModel onSubmit={onSubmit} closeModel={closeModel} />
          <PackagesItem
            oprenModel={oprenModel}
            moment={moment}
            editHandle={editHandle}
            sorting={sorting}
            AddModel={AddModel}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            page={page}
            record={record}
          />
        </Container>
      </Fragment>
    </>
  );
};

export default Package;
