import React, { useContext } from "react";
import { Row } from "reactstrap";
import DashboardCard from "./DashboardCard";
import { useNavigate } from "react-router";
import { classes } from "../../../data/layouts";
import moment from "moment";
import QrListAllContext from "../../../context/QrListAllContext/QrListAllContext";
import { QR_GUNRATE_STATUS } from "../../../constant";

const SellerDeshboaed = (props) => {
  const { sellerDeshboaed, orgDetails, genQr } = props;
  const { setStatusFilter, setExpireAtFilter, setToDayOrder, setSearchName } =
    useContext(QrListAllContext);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const handleRedirect = async (condition) => {
    setSearchName("Month");
    if (condition === "next-month") {
      await setExpireAtFilter({
        // startExpiredDate: moment().format("YYYY-MM-DD hh:mm:ss"),
        // endExpiredDate: moment().add(30, "days").format("YYYY-MM-DD hh:mm:ss"),
        startExpiredDate: moment()
          .add(-30, "days")
          .format("YYYY-MM-DD hh:mm:ss"),
        endExpiredDate: moment().format("YYYY-MM-DD hh:mm:ss"),
      });
    } else {
      await setExpireAtFilter({
        expiredAt: moment().format("YYYY-MM-DD hh:mm:ss"),
        // expiredEndDate: moment().format("YYYY-MM-DD"),
      });
    }
    history(`/qrListAll/qrListAll/${layout}`);
  };

  const handleRedirectOrderList = async (condition) => {
    setSearchName("Qr");
    if (condition === "active") {
      setStatusFilter(QR_GUNRATE_STATUS.CREATED);
    } else {
      setStatusFilter(QR_GUNRATE_STATUS.INACTIVE);
    }
    history(`/qrListAll/qrListAll/${layout}`);
    // history(`/orderList/orderList/${layout}`);
  };

  const handleRedirectBalanceList = async (condition) => {
    setSearchName("Order");
    if (condition === "total") {
      await setToDayOrder("");
    } else {
      await setToDayOrder({
        createdAt: moment().format("YYYY-MM-DD hh:mm:ss"),
        // expiredEndDate: moment().format("YYYY-MM-DD"),
      });
    }
    history(`/qrListAll/qrListAll/${layout}`);
  };

  return (
    <Row className="size-column">
      <Row>
        <DashboardCard
          lab1={"Total Balance"}
          val1={Number(orgDetails.qrBal) + Number(genQr.count)}
          lab2={"Available Balance"}
          val2={orgDetails.qrBal}
          // event1={() => handleRedirectBalanceList()}
          // event2={() => handleRedirectBalanceList()}
        />
        <DashboardCard
          lab1={"Total Order"}
          val1={genQr.count}
          lab2={"Today's Order"}
          val2={sellerDeshboaed.todayQrOrder}
          event1={() => handleRedirectBalanceList("total")}
          event2={() => handleRedirectBalanceList()}
        />
        <DashboardCard
          lab1={"Active QR"}
          val1={sellerDeshboaed.activeQr}
          lab2={"Inactive QR"}
          val2={sellerDeshboaed.inActiveQr}
          event1={() => handleRedirectOrderList("active")}
          event2={() => handleRedirectOrderList()}
        />
        <DashboardCard
          lab1={"Expire Next Month"}
          val1={sellerDeshboaed.expireInMonthQrCount}
          lab2={"Expire QR"}
          val2={sellerDeshboaed.expiredQrCount}
          event1={() => handleRedirect("next-month")}
          event2={() => handleRedirect()}
        />
      </Row>
    </Row>
  );
};

export default SellerDeshboaed;
