import axios from "axios";
import {
  ORG_THEME_ACCESS_DETAILS,
  ORG_THEME_ACCESS_LIST,
  ORG_THEME_ACCESS_STATUS_CHANGE,
  THEME_LIST,
} from "../../constant/API constant";

export const getOrgThemeAccess = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ORG_THEME_ACCESS_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const getOrgThemeAccessDetails = (orgSellerAccessThemeId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(
    `${process.env.REACT_APP_API_URL}${ORG_THEME_ACCESS_DETAILS}`,
    {
      headers: { Authorization: jwt_token },
      params: { orgSellerAccessThemeId },
    }
  );
};

export const updateOrgThemeAccess = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_THEME_ACCESS_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};

export const statusChange = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ORG_THEME_ACCESS_STATUS_CHANGE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { orgSellerAccessThemeId: formData.orgSellerAccessThemeId },
    }
  );
};

export const addOrgThemeAccess = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ORG_THEME_ACCESS_LIST}`,
    formData,
    {
      headers: { Authorization: jwt_token },
    }
  );
};
export const deleteOrgThemeAccess = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.delete(
    `${process.env.REACT_APP_API_URL}${ORG_THEME_ACCESS_LIST}`,
    {
      headers: { Authorization: jwt_token },
      params: paramObj,
    }
  );
};
export const getThemeList = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL_QR_NFC}${THEME_LIST}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};
