import React, { useContext } from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { NFC_LIST } from "../../constant";
import NfcListContext from "../../context/nfcListContext/NfcListContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const EditStatusModal = (props) => {
  const { handleSubmit, statusModelClose } = props;

  const { selectStatus, setOrderStatusId, orderValue, orderStatusId } =
    useContext(NfcListContext);

  const { loaderShow } = useContext(LoaderContext);
  return (
    <Modal
      size="lm"
      isOpen={selectStatus}
      toggle={() => statusModelClose()}
      centered
    >
      <ModalHeader toggle={() => statusModelClose()}>
        {NFC_LIST.SELECT_STATUS}
      </ModalHeader>
      <ModalBody>
        <Form className="needs-validation" onSubmit={(e) => handleSubmit(e)}>
          <select
            className="form-select"
            name="orglist"
            style={{ marginBottom: "12px" }}
            onChange={(e) => setOrderStatusId(e.target.value)}
          >
            <option value="select">--select--</option>
            {orderValue.map((elem) => {
              return (
                <option
                  key={elem.orderStatusId}
                  value={elem.orderStatusId}
                  selected={
                    elem.orderStatusId === orderStatusId ? "selected" : ""
                  }
                >
                  {elem.statusName}
                </option>
              );
            })}
          </select>
          <button
            className="btn btn-primary"
            type="submit"
            value="Submit"
            disabled={loaderShow === true}
          >
            {NFC_LIST.SAVE}
          </button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EditStatusModal;
