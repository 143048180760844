import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { ORGANIZATION, PLAN_TYPE, USER_ROLE } from "../../constant";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import { PlanList } from "../../api/Organization Api/OrganizationApi";
import { QrRenewal } from "../../api/QR List All Api/QrListAllApi";
import { Zoom, toast } from "react-toastify";
import moment from "moment";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";
import { Check } from "react-feather";
import { subScriptionList } from "../../api/Qr Order List Api/OrderListApi";

const QrRenewModal = (props) => {
  const { getData } = props;
  const {
    renewModal,
    setRenewModal,
    setQrId,
    qrId,
    solutionDetails,
    setSolutionDetails,
    setPlanAmount,
    setTests,
    setPlanName,
    setPlanMasterId,
    setPlanValidity,
    tests,
    planMasterId,
  } = useContext(QrListAllContext);
  const [planList, setPlanList] = useState([]);
  const [formData, setFormData] = useState({
    amount: "",
    planName: "",
    note: "",
    validityMonth: "",
    planMasterId: "",
    solutionDetails: [],
  });
  const history = useNavigate();
  const { socket } = useContext(SocketContext);

  const handleEmpty = () => {
    setQrId("");
    setFormData({
      amount: "",
      planName: "",
      note: "",
      validityMonth: "",
      planMasterId: "",
      solutionDetails: [],
    });
    setRenewModal(false);
  };

  const getPlanList = async () => {
    let paramObj = {
      showAll: true,
      type: PLAN_TYPE.QR_PLAN,
    };
    try {
      const { data } = await PlanList(paramObj);
      if (data.status === 200) {
        setPlanList(data.payload.data);
      } else {
        setPlanList([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const getSubScription = async () => {
    try {
      let paramsObj = {
        search: { isActive: true },
        showAll: true,
      };
      const { data } = await subScriptionList({ paramsObj });
      if (data.status === 200) {
        setSolutionDetails(() =>
          data.payload.data?.map((elem) => {
            return {
              price: elem.price,
              solutionId: elem.solutionId,
              solutionName: elem.solutionName,
              isChecked: false,
            };
          })
        );
      } else {
        setSolutionDetails([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getPlanList();
    getSubScription();
  }, []);

  const changeInput = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleChangePlan = (e) => {
    let [value] = planList.filter((elem) => elem.planName === e.target?.value);
    setPlanName(value?.planName ?? "");
    setPlanMasterId(value?.planMasterId ?? "");
    setPlanValidity(value?.validityMonth ?? "");
    setSolutionDetails((prev) => {
      return prev.map((elem) => {
        let findValue = value?.solutionDetails.find(
          (findEle) => findEle.solutionId === elem?.solutionId
        );
        if (findValue) {
          return {
            ...findValue,
            price: elem.price,
            isChecked: true,
          };
        } else {
          return {
            ...elem,
            isChecked: false,
          };
        }
      });
    });
    setTests([value?.planName]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const planNames = tests.join(" + ");
      let newData = {
        ...formData,
        planName: planNames,
        planMasterId,
        solutionIds: solutionDetails
          .filter((elem) => elem.isChecked === true)
          .map((elem) => ({
            solutionId: elem.solutionId,
            solutionName: elem.solutionName,
          })),
      };
      delete newData.solutionDetails;

      const { data } = await QrRenewal({ formData: newData, qrId });
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        handleEmpty();
        getData();
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  let planTotal = useMemo(() => {
    let total = solutionDetails.reduce((acc, cur) => {
      if (cur.isChecked) {
        return +acc + +cur.price;
      } else {
        return +acc;
      }
    }, 0);
    setPlanAmount(total);
    return total;
  }, [solutionDetails]);

  const handleSelectPlan = (e, index) => {
    let old = [...solutionDetails];
    old[index].isChecked = e.target.checked;
    setSolutionDetails(old);
    if (e.target.checked) {
      setTests((prev) => [...prev, e.target.name]);
    } else {
      setTests((prev) => prev.filter((name) => name !== e.target.name));
    }
  };
  return (
    <Modal size="lg" isOpen={renewModal} toggle={() => handleEmpty()}>
      <ModalHeader toggle={() => handleEmpty()}>{"Renew Plan"}</ModalHeader>
      <ModalBody>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col md="4 mb-4">
              <Label htmlFor="validationCustom08">
                {ORGANIZATION.PLAN_NAME}
              </Label>
              <select
                className="form-control"
                onChange={(e) => handleChangePlan(e)}
                value={formData.planName}
                name="planName"
              >
                <option value="">--select--</option>
                {planList.map((elem) => {
                  return <option value={elem.planName}>{elem.planName}</option>;
                })}
              </select>
            </Col>
            <Col md="4 mb-4">
              <Label htmlFor="validationCustom08">
                {ORGANIZATION.PLAN_AMOUNT}
              </Label>
              <Input
                className="form-control"
                type="number"
                onChange={(e) => changeInput(e)}
                value={formData.amount}
                name="amount"
                readOnly={
                  USER_ROLE.SELLER === +localStorage.getItem("roleId")
                    ? true
                    : false
                }
              />
            </Col>
            <Col md="4 mb-4">
              <Label htmlFor="validationCustom08">
                {ORGANIZATION.PLAN_VALIDITY}
              </Label>
              <Input
                className="form-control"
                type="number"
                onChange={(e) => changeInput(e)}
                value={formData.validityMonth}
                name="validityMonth"
                readOnly={
                  USER_ROLE.SELLER === +localStorage.getItem("roleId")
                    ? true
                    : false
                }
                required
              />
            </Col>
            <Col md="12 mb-4">
              <div style={{ columnCount: "3" }}>
                {solutionDetails?.map((elem, index) => {
                  return (
                    <>
                      <div className="d-flex gap-2" key={elem?.solutionId}>
                        <input
                          id="checkbox-primary-1"
                          type="checkbox"
                          name={elem?.solutionName}
                          checked={elem?.isChecked}
                          onChange={(e) => {
                            handleSelectPlan(e, index);
                          }}
                        />
                        <p className="m-0">
                          {elem?.solutionName} ({elem.price})
                        </p>{" "}
                        {/* {solutionDetails?.length - 1 !== index
                                  ? "|"
                                  : ""}{" "} */}
                      </div>
                    </>
                  );
                })}
              </div>
            </Col>
            <Col md="12 mb-4">
              <Label htmlFor="validationCustom08">{ORGANIZATION.NOTE}</Label>
              <textarea
                className="form-control"
                type="text"
                onChange={(e) => changeInput(e)}
                value={formData.note}
                name="note"
              />
            </Col>
          </Row>
          <Button type="submit">Submit</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default QrRenewModal;
