import * as React from "react";
import { useState } from "react";
import AdminDeshboardContext from "./AdminDeshboardContext";
import man from "../../assets/images/dashboard/profile.jpg";
const AdminDeshboardState = (props) => {
  const [val, setVal] = useState("");
  const [profile, setProfile] = useState(
    localStorage.getItem("profileURL") || man
  );

  return (
    <>
      <AdminDeshboardContext.Provider value={{ val, setVal ,profile, setProfile}}>
        {props.children}
      </AdminDeshboardContext.Provider>
    </>
  );
};

export default AdminDeshboardState;
