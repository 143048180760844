import React, { Fragment, useContext, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { ORDER_LIST, USER_ROLE } from "../../constant";
import { useEffect } from "react";
import { toast, Zoom } from "react-toastify";
import moment from "moment/moment";
import OrderListItem from "./orderListItem";
import { Container } from "reactstrap";
import CreateQr from "./createQr";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import {
  Create_Qr,
  OrderLists,
  Qe_Order_List_Title_Update,
  Qr_Order_List_Details,
  Regunrate_Qr,
} from "../../api/Qr Order List Api/OrderListApi";
import TitleUpdate from "./titleUpdate";
import OrderListContext from "../../context/orderListContext/OrderListContext";
import { useNavigate } from "react-router";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { tableId } from "../../utils/helper";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";

const OrderList = () => {
  const {
    setqrValue,
    qty,
    setQty,
    title,
    setTitle,
    qrOrderId,
    setQrOrderId,
    setIsHovering,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    setQrModal,
    qrmodal,
    progress,
    setProgress,
    setEditTitle,
    editTitle,
    filter,
    qrValue,
    dateFilter,
    planName,
    setPlanName,
    planValidity,
    setPlanValidity,
    planAmount,
    setPlanAmount,
    note,
    setNote,
    expiredFilter,
    statusFilter,
    planMasterId,
    setPlanMasterId,
    solutionDetails,
    tests,
    setTests,
  } = useContext(OrderListContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * order list get function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          title: filter,
          createdAt: dateFilter,
        };
      }
      if (dateFilter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          createdAt: dateFilter,
        };
      }
      if (statusFilter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          status: statusFilter,
        };
      }
      if (expiredFilter.expiredStartDate && expiredFilter.expiredEndDate) {
        paramsObj["search"] = { ...paramsObj.search, ...expiredFilter };
      }
      const { data } = await OrderLists(paramsObj);
      if (data.status === 200) {
        setqrValue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setqrValue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    setProgress(100);
  }, [
    page,
    pageSize,
    sortOrder,
    sortBy,
    filter,
    dateFilter,
    expiredFilter,
    statusFilter,
  ]);

  tableId(page, pageSize, qrValue);

  /**
   * sorting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const layout = localStorage.getItem("layout");
  const navigate = useNavigate();

  /**
   * empty feild to fucntion call
   */

  const emplyFeild = () => {
    setPlanName("");
    setTests([]);
    setQty("1");
    setTitle("");
    setLoaderShow(false);
    setPlanName("");
    setPlanValidity("");
    setPlanAmount("");
    setNote("");
    setPlanMasterId("");
  };

  /**
   * fill feild to function call
   */

  const fillFeild = () => {
    setQty(qty);
    setTitle(title);
    setLoaderShow(false);
  };

  /**
   * create qr function call
   */
  const createQr = async (e) => {
    e.preventDefault();
    let formData;
    setLoaderShow(!loaderShow);
    const planNames = tests.join(" + ");
    formData = {
      qty,
      title,
      amount: +planAmount,
      planName: planNames,
      validityMonth: +planValidity,
      note,
      planMasterId,
      solutionIds: solutionDetails
        .filter((elem) => elem.isChecked === true)
        .map((elem) => ({
          solutionId: elem.solutionId,
          solutionName: elem.solutionName,
        })),
    };
    try {
      const { data } = await Create_Qr(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        navigate(`${process.env.PUBLIC_URL}/qrListAll/qrListAll/${layout}`);
        setQrModal(false);
        getData();
        emplyFeild();
        setLoaderShow(false);
      } else {
        fillFeild();
        setLoaderShow(false);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
      setLoaderShow(false);
    }
  };

  /**
   * handle regenerate function call
   */

  const handleRegenerate = async (qrOrderId) => {
    let formData;
    formData = {
      qrOrderId,
    };

    try {
      const { data } = await Regunrate_Qr(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
      } else {
        setqrValue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * qr order detaila fill feild to function call
   */

  const handleEdit = async (qrOrderId) => {
    setQrOrderId(qrOrderId);
    setEditTitle(true);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Qr_Order_List_Details(qrOrderId);
      if (data.status === 200) {
        setEditTitle(true);
        setTitle(data.payload.data.title);
        setLoaderShow(false);
      } else {
        setqrValue([]);
      }
    } catch (error) {
      setLoaderShow(false);
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * qr order title update form to functopn call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoaderShow(!loaderShow);
    let formData;
    formData = {
      title,
    };
    try {
      const { data } = await Qe_Order_List_Title_Update(qrOrderId, formData);
      if (data.status === 200) {
        setEditTitle(false);
        getData();
        toast.success(data.message, {
          autoClose: 1000,
        });
        emplyFeild();
      } else {
        setTitle(title);
        setLoaderShow(false);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   *  close model functionc call
   */

  const closeModel = () => {
    emplyFeild();
    setQrModal(!qrmodal);
  };

  /**
   * title edit model close function call
   */

  const titleEditModel = () => {
    emplyFeild();
    setEditTitle(!editTitle);
  };

  return (
    <>
      <Fragment>
        <Breadcrumb parent={ORDER_LIST.PERENT} />
        <Container fluid={true}>
          <TopLoadingBar progress={progress} setProgress={setProgress} />
          <TitleUpdate onSubmit={onSubmit} titleEditModel={titleEditModel} />
          {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
            <div>
              <OrderListItem
                moment={moment}
                handleMouseOut={handleMouseOut}
                handleMouseOver={handleMouseOver}
                sorting={sorting}
                handleEdit={handleEdit}
                setPage={setPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                page={page}
                record={record}
              />
            </div>
          ) : (
            <div>
              <CreateQr createQr={createQr} closeModel={closeModel} />
              <OrderListItem
                moment={moment}
                handleMouseOut={handleMouseOut}
                handleMouseOver={handleMouseOver}
                sorting={sorting}
                handleRegenerate={handleRegenerate}
                handleEdit={handleEdit}
                setPage={setPage}
                setPageSize={setPageSize}
                pageSize={pageSize}
                page={page}
                record={record}
              />
            </div>
          )}
        </Container>
      </Fragment>
    </>
  );
};

export default OrderList;
