import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, ORDER_LIST, PLAN_TYPE } from "../../constant";
import OrderListContext from "../../context/orderListContext/OrderListContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { PlanList } from "../../api/Organization Api/OrganizationApi";
import { userLogout } from "../../auth/Logout";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { Zoom, toast } from "react-toastify";
import { subScriptionList } from "../../api/Qr Order List Api/OrderListApi";
const CreateQr = (props) => {
  const { createQr, closeModel } = props;
  const {
    qrmodal,
    setTitle,
    title,
    planName,
    setPlanName,
    planValidity,
    setPlanValidity,
    planAmount,
    setPlanAmount,
    note,
    setNote,
    setPlanMasterId,
    solutionDetails,
    setSolutionDetails,
    tests,
    setTests,
  } = useContext(OrderListContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);

  const [planList, setPlanList] = useState([]);

  const { loaderShow } = useContext(LoaderContext);

  const getPlanList = async () => {
    let paramObj = {
      showAll: true,
      type: PLAN_TYPE.QR_PLAN,
    };
    try {
      const { data } = await PlanList(paramObj);
      if (data.status === 200) {
        setPlanList(data.payload.data);
      } else {
        setPlanList([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  const getSubScription = async () => {
    try {
      let paramsObj = {
        search: { isActive: true },
        showAll: true,
      };
      const { data } = await subScriptionList({ paramsObj });
      if (data.status === 200) {
        setSolutionDetails(() =>
          data.payload.data?.map((elem) => {
            return {
              price: elem.price,
              solutionId: elem.solutionId,
              solutionName: elem.solutionName,
              isChecked: false,
            };
          })
        );
      } else {
        setSolutionDetails([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getPlanList();
    getSubScription();
  }, []);

  const handleChangePlan = (e) => {
    let [value] = planList.filter((elem) => elem.planName === e.target?.value);
    setPlanName(value?.planName ?? "");
    setPlanMasterId(value?.planMasterId ?? "");
    setPlanValidity(value?.validityMonth ?? "");
    setSolutionDetails((prev) => {
      return prev.map((elem) => {
        let findValue = value?.solutionDetails.find(
          (findEle) => findEle.solutionId === elem?.solutionId
        );
        if (findValue) {
          return {
            ...findValue,
            price: elem.price,
            isChecked: true,
          };
        } else {
          return {
            ...elem,
            isChecked: false,
          };
        }
      });
    });
    setTests([value?.planName]);
  };

  let planTotal = useMemo(() => {
    let total = solutionDetails.reduce((acc, cur) => {
      if (cur.isChecked) {
        return +acc + +cur.price;
      } else {
        return +acc;
      }
    }, 0);
    setPlanAmount(total);
    return total;
  }, [solutionDetails]);

  const handleSelectPlan = (e, index) => {
    let old = [...solutionDetails];
    old[index].isChecked = e.target.checked;
    setSolutionDetails(old);
    if (e.target.checked) {
      setTests((prev) => [...prev, e.target.name]);
    } else {
      setTests((prev) => prev.filter((name) => name !== e.target.name));
    }
  };

  return (
    <Modal size="lg" isOpen={qrmodal} toggle={() => closeModel()}>
      <ModalHeader toggle={() => closeModel()}>
        {ORDER_LIST.CREATE_QR}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => createQr(e)}
                >
                  <Row>
                    {/* <Col
                      md="6 mb-4"
                      className="d-none"
                      style={{ display: "none" }}
                    >
                      <Label htmlFor="validationCustom4">
                        {ORDER_LIST.QTY}
                      </Label>
                      <Input
                        className="form-control"
                        name="qty"
                        id="validationCustom4"
                        type="number"
                        placeholder="QTY"
                        onChange={(e) => setQty(e.target.value)}
                        value={qty}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col> */}
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom5">
                        {ORDER_LIST.TITLE}
                      </Label>
                      <Input
                        className="form-control"
                        name="title"
                        id="validationCustom4"
                        type="text"
                        placeholder={ORDER_LIST.TITLE}
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom08">
                        {ORDER_LIST.PLAN_NAME}
                      </Label>
                      <select
                        className="form-control"
                        onChange={(e) => handleChangePlan(e)}
                        value={planName}
                        // required
                      >
                        <option value="">--select--</option>
                        {planList.map((elem, index) => {
                          return (
                            <option value={elem.planName} key={index}>
                              {elem.planName}
                            </option>
                          );
                        })}
                      </select>
                      {/* <Input
                        className="form-control"
                        name={ORDER_LIST.PLAN_NAME}
                        id="validationCustom08"
                        type="text"
                        placeholder={ORDER_LIST.PLAN_NAME}
                        onChange={(e) => setPlanName(e.target.value)}
                        value={planName}
                        required
                      /> */}
                      <div className="invalid-feedback">
                        {"Please provide a plan name."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4">
                      <div style={{ columnCount: "3" }}>
                        {solutionDetails?.map((elem, index) => {
                          return (
                            <>
                              <div
                                className="d-flex gap-2"
                                key={elem?.solutionId}
                              >
                                <input
                                  id="checkbox-primary-1"
                                  type="checkbox"
                                  name={elem?.solutionName}
                                  checked={elem?.isChecked}
                                  onChange={(e) => {
                                    handleSelectPlan(e, index);
                                  }}
                                />
                                <p>
                                  {elem?.solutionName} ({elem.price})
                                </p>{" "}
                                {/* {solutionDetails?.length - 1 !== index
                                  ? "|"
                                  : ""}{" "} */}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom08">
                        {ORDER_LIST.PLAN_AMOUNT}
                      </Label>
                      <Input
                        className="form-control"
                        name={ORDER_LIST.PLAN_AMOUNT}
                        id="validationCustom08"
                        type="number"
                        placeholder={ORDER_LIST.PLAN_AMOUNT}
                        onChange={(e) => setPlanAmount(e.target.value)}
                        value={planAmount}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a plan amount."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom08">
                        {ORDER_LIST.PLAN_VALIDITY}
                      </Label>
                      <Input
                        className="form-control"
                        name={ORDER_LIST.PLAN_VALIDITY}
                        id="validationCustom08"
                        type="number"
                        placeholder={ORDER_LIST.PLAN_VALIDITY}
                        onChange={(e) => setPlanValidity(e.target.value)}
                        value={planValidity}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a plan validity."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4">
                      <Label htmlFor="validationCustom08">
                        {ORDER_LIST.PLAN_NOTE}
                      </Label>
                      <Input
                        className="form-control"
                        name={ORDER_LIST.PLAN_NOTE}
                        id="validationCustom08"
                        type="text"
                        placeholder={ORDER_LIST.PLAN_NOTE}
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                      />
                      <div className="invalid-feedback">
                        {"Please provide a note."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12 mb-4">
                      <Label htmlFor="validationCustom08">
                        Total Plans Amount :{" "}
                        <span style={{ color: "red", fontWeight: 500 }}>
                          Rs.{planTotal ?? 0}
                        </span>
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        style={{ width: "15rem" }}
                        disabled={loaderShow === true}
                      >
                        {BUTTON.GENERATE_QR}
                      </button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default CreateQr;
