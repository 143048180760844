import React from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
const DashboardCard = ({
  lab1,
  lab2,
  val1,
  val2,
  event1 = null,
  event2 = null,
}) => {
  return (
    <Col xl="4" lg="12" md="6" className="box-col-6">
      <Card className="o-hidden">
        <CardBody>
          <div className="follow">
            <Row>
              {/* <Col col="6" className="text-md-end border-end"> */}
              <Col
                col="6"
                className="text-md-start"
                onClick={() => event1()}
                style={{ cursor: `${event2 !== null ? "pointer" : ""}` }}
              >
                <p className="f-w-500 font-roboto">{lab1}</p>
                <h4>
                  <span>
                    <CountUp end={val1} />
                  </span>
                </h4>
              </Col>
              <Col
                col="6"
                className="text-md-start"
                onClick={() => event2()}
                style={{ cursor: `${event1 !== null ? "pointer" : ""}` }}
              >
                <p className="f-w-500 font-roboto">{lab2}</p>
                <h4>
                  <CountUp end={val2} />
                </h4>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DashboardCard;
