// dashbaord
import Ecommerce from "../components/dashboard/ecommerce";
import Users from "../components/users/users";
import Organization from "../components/organization/organization";
import Packages from "../components/packages/package";
import Shippingmaster from "../components/shipingmaster/shipingmaster";
import OrderStatusMaster from "../components/orderstatusmaster/orderstatusmaster";
import OrderList from "../components/orderList/orderList";
import OrderLists from "../components/nfcOrderList/orderList";
import NfcList from "../components/nfcList/nfcList";
import UserProfile from "../components/userProfile/userProfile";
import QrListAll from "../components/qrListAll/qrListAll";
import PrivillageManager from "../components/PrivillageManager/PrivillageManager";
import ModualManager from "../components/ModualManager/ModualManager";
import GenratedQrAndNfc from "../components/organization/genratedQrAndNfc";
import GeneratedNfc from "../components/organization/generatedNfc";
import WhiteLabelDomain from "../components/WhiteLabelDomain/whiteLabelDomain";
import Wallet from "../components/wallet/Walltet";
import OrgThemeAccessMaster from "../components/orgThemeAccessMaster/orgThemeAccessMaster";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard/:layout/`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/users/:layout/`,
    Component: <Users />,
  },
  {
    path: `${process.env.PUBLIC_URL}/organization/organization/:layout/`,
    Component: <Organization />,
  },
  {
    path: `${process.env.PUBLIC_URL}/organization/:orgId/theme-access/:layout/`,
    Component: <OrgThemeAccessMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/WhiteLabelDomain/whiteLabelDomain/:layout/`,
    Component: <WhiteLabelDomain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/organization/genratedQrAndNfc/:layout/`,
    Component: <GenratedQrAndNfc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/organization/generatedNfc/:layout/`,
    Component: <GeneratedNfc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/packages/package/:layout/`,
    Component: <Packages />,
  },
  {
    path: `${process.env.PUBLIC_URL}/shipingmaster/shipingmaster/:layout/`,
    Component: <Shippingmaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/orderstatusmaster/orderstatusmaster/:layout/`,
    Component: <OrderStatusMaster />,
  },
  {
    path: `${process.env.PUBLIC_URL}/orderList/orderList/:layout/`,
    Component: <OrderList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/qrListAll/qrListAll/:layout/`,
    Component: <QrListAll />,
  },
  {
    path: `${process.env.PUBLIC_URL}/nfcOrderList/orderList/:layout/`,
    Component: <OrderLists />,
  },
  {
    path: `${process.env.PUBLIC_URL}/nfcList/nfcList/:layout/`,
    Component: <NfcList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/userProfile/userProfile/:layout/`,
    Component: <UserProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/PrivillageManager/PrivillageManager/:layout/`,
    Component: <PrivillageManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ModualManager/ModualManager/:layout/`,
    Component: <ModualManager />,
  },
  {
    path: `${process.env.PUBLIC_URL}/wallet/Walltet/:layout/`,
    Component: <Wallet />,
  },
];
