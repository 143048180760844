import React, { useContext, useState } from "react";
import { toast, Zoom } from "react-toastify";
import { Password_Change } from "../../api/SellerPasswordChangeApi/PasswordChangeApi";
import SellerForgetPasswordItem from "./SellerForgetPasswordItem";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";

const SellerForgerPassword = (props) => {
  const { modal, setModal } = props;
  const [togglePassword, setTogglePassword] = useState(false);
  const [togglePassword1, setTogglePassword1] = useState(false);
  const [togglePassword2, setTogglePassword2] = useState(false);
  const [password, setPassword] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const history = useNavigate();
  const { socket } = useContext(SocketContext);

  /**
   * forget password function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    formData = {
      oldPassword: password,
      password: newpassword,
      confirmPassword: changePassword,
    };

    try {
      const { data } = await Password_Change(formData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setModal(false);
        setPassword("");
        setNewPassword("");
        setChangePassword("");
      } else {
        setPassword(password);
        setNewPassword(newpassword);
        setChangePassword(changePassword);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  return (
    <>
      <SellerForgetPasswordItem
        modal={modal}
        setModal={setModal}
        onSubmit={onSubmit}
        togglePassword={togglePassword}
        setTogglePassword={setTogglePassword}
        setTogglePassword2={setTogglePassword2}
        setPassword={setPassword}
        togglePassword2={togglePassword2}
        setChangePassword={setChangePassword}
        password={password}
        changePassword={changePassword}
        togglePassword1={togglePassword1}
        setNewPassword={setNewPassword}
        newpassword={newpassword}
        setTogglePassword1={setTogglePassword1}
      />
    </>
  );
};

export default SellerForgerPassword;
