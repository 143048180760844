import React, { useState, useEffect, useContext } from "react";
import man from "../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { toast, Zoom } from "react-toastify";

import jwtImg from "../assets/images/jwt.svg";

import {
  Password,
  EmailAddress,
  AUTH0,
  JWT,
  LoginWithJWT,
  ENDPOINT_IMAGE,
} from "../constant";
import { useNavigate } from "react-router";
import { classes } from "../data/layouts";
import { Login } from "../api/Login Api/LoginApi";
import { Toast } from "./signinToast";
import LoaderContext from "../context/LoaderContext/LoaderContext";

const Logins = () => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState("jwt");
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("name"));
  const [roleId, setroleId] = useState(localStorage.getItem("roleId"));
  const [orgId, setOrgId] = useState(localStorage.getItem("orgId"));

  const [error, setError] = useState(false);

  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const login = async (e) => {
    e.preventDefault();
    if (email.length === 0 || password.length === 0) {
      setError(true);
    } else {
      setLoaderShow(!loaderShow);
      try {
        const { data } = await Login(email, password);
        if (data.status === 200) {
          setValue(man);
          setroleId(data.payload.data.roleId);
          setOrgId(data.payload.data.orgId);
          localStorage.setItem("token", `Bearer ${data.payload.token}`);
          localStorage.setItem(
            "isWhatsAppMsgAccess",
            data.payload.data.org.isWhatsAppMsgAccess
          );
          localStorage.setItem(
            "mobileNoForSendWpMsg",
            data.payload.data.org.mobileNoForSendWpMsg
          );
          localStorage.setItem(
            "dialCodeMobileNoForSendWpMsg",
            data.payload.data.org.dialCodeMobileNoForSendWpMsg
          );
          setTimeout(() => {
            history(`${process.env.PUBLIC_URL}/dashboard/${layout}`);
          }, 200);
          setName(data.payload.data.name);
          setLoaderShow(false);
          toast.success(data.message, {
            autoClose: 1000,
            transition: Zoom,
          });
          return data.payload;
        }
      } catch (error) {
        setLoaderShow(false);
        Toast.fire({
          icon: "error",
          title: error?.response?.data?.message,
        });
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("name", name);
    localStorage.setItem("roleId", roleId);
    localStorage.setItem("orgId", orgId);
  }, [value, name, roleId, orgId]);

  return (
    <>
      <Container fluid={true} className="p-0">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="index.html">
                    <img
                      className="img-fluid for-light"
                      src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                      style={{ height: "50px" }}
                      alt=""
                    />

                    <img
                      className="img-fluid for-dark"
                      src={`${process.env.REACT_APP_BUCKET_URL}${ENDPOINT_IMAGE}`}
                      style={{ height: "50px" }}
                      alt=""
                    />
                  </a>
                </div>
                <div className="login-main login-tab">
                  <Nav className="border-tab flex-column" tabs>
                    <NavItem style={{ display: "none" }}>
                      <NavLink
                        className={selected === "jwt" ? "active" : ""}
                        onClick={() => setSelected("jwt")}
                      >
                        <img src={jwtImg} alt="" />
                        <span>{JWT}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={selected}
                    className="content-login"
                    style={{ height: "auto" }}
                  >
                    <TabPane className="fade show" tabId="jwt">
                      <Form className="theme-form" onSubmit={(e) => login(e)}>
                        <h4>
                          {selected === "firebase"
                            ? "Admin Login"
                            : "Admin Login "}
                        </h4>
                        <p>{"Enter your email & password"}</p>
                        {error && email.length <= 0 ? (
                          <div className="mb-3">
                            <Label
                              className="col-form-label"
                              style={{ color: "red" }}
                            >
                              {EmailAddress}
                            </Label>
                            <Input
                              className="form-control"
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              defaultValue={email}
                              placeholder="Enter a Valid Email"
                              style={{ border: "2px solid red" }}
                            />
                          </div>
                        ) : (
                          <div className="mb-3">
                            <Label className="col-form-label">
                              {EmailAddress}
                            </Label>
                            <Input
                              className="form-control"
                              type="email"
                              onChange={(e) => setEmail(e.target.value)}
                              defaultValue={email}
                              placeholder="Enter a Valid Email"
                              // required="Enter a Valid Email"
                            />
                          </div>
                        )}

                        {error && password.length <= 0 ? (
                          <div className="mb-3 position-relative">
                            <Label
                              className="col-form-label"
                              style={{ color: "red" }}
                            >
                              {Password}
                            </Label>
                            <Input
                              className="form-control"
                              type={togglePassword ? "text" : "password"}
                              onChange={(e) => setPassword(e.target.value)}
                              defaultValue={password}
                              placeholder="Enter a Valid Password"
                              style={{ border: "2px solid red" }}
                            />
                            <div
                              className="show-hide"
                              onClick={() => setTogglePassword(!togglePassword)}
                            >
                              <span
                                className={togglePassword ? "" : "show"}
                              ></span>
                            </div>
                          </div>
                        ) : (
                          <div className="mb-3 position-relative">
                            <Label className="col-form-label">{Password}</Label>
                            <Input
                              className="form-control"
                              type={togglePassword ? "text" : "password"}
                              onChange={(e) => setPassword(e.target.value)}
                              defaultValue={password}
                              placeholder="Enter a Valid Password"
                            />
                            <div
                              className="show-hide"
                              onClick={() => setTogglePassword(!togglePassword)}
                            >
                              <span
                                className={togglePassword ? "" : "show"}
                              ></span>
                            </div>
                          </div>
                        )}

                        <div className="login-btn mb-0">
                          <div className="ms-3">
                            <Label className="text-muted" for="checkbox1">
                              {"Please Enter Valid Email and Password"}
                            </Label>
                          </div>
                          <Button color="primary" type="submit" value="submit">
                            {LoginWithJWT}
                          </Button>
                        </div>
                      </Form>
                    </TabPane>
                    <TabPane className="fade show" tabId="auth0">
                      <div className="auth-content">
                        <img
                          src={require("../assets/images/auth-img.svg")}
                          alt=""
                        />
                        <h4>{"Welcome to login with Auth0"}</h4>
                        <p>
                          {
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
                          }
                        </p>
                        <Button
                          color="info"
                          onClick={loginWithRedirect}
                          disabled={loaderShow === true}
                        >
                          {AUTH0}
                        </Button>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

// export default withRouter(Logins);
export default Logins;
