import * as React from "react";
import { useState } from "react";
import OrgThemeAccessContext from "./OrgThemeAccessContext";

const OrgThemeAccessState = (props) => {
  const [packageValue, setPackageValue] = useState([]);
  const [formvalue, setformvalue] = useState([]);

  const [modal, setModal] = useState(false); //ADD DATA MODEL -->

  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [totalRecord, setTotalRecord] = useState();
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [formData, setFormData] = useState({ templateIds: [] });

  const [progress, setProgress] = useState(0);
  const [editIndex, seteditIndex] = useState(-1);

  return (
    <>
      <OrgThemeAccessContext.Provider
        value={{
          packageValue,
          setPackageValue,
          formvalue,
          setformvalue,
          modal,
          setModal,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          totalRecord,
          setTotalRecord,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          progress,
          setProgress,
          editIndex,
          seteditIndex,
          formData,
          setFormData,
        }}
      >
        {props.children}
      </OrgThemeAccessContext.Provider>
    </>
  );
};

export default OrgThemeAccessState;
