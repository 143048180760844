import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast, Zoom } from "react-toastify";
import Breadcrumb from "../../layout/breadcrumb";
import { Container } from "reactstrap";
import QrListAllItem from "./WalletItem";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import { WALLET_LIST } from "../../constant";
import { Org_Wallet } from "../../api/Organization Api/OrganizationApi";
import WalletContext from "../../context/wallterContext/WalletContext";
import SocketContext from "../../context/SocketContext/SocketContext";
import { useNavigate } from "react-router";
import { userLogout } from "../../auth/Logout";

const Wallet = () => {
  const {
    setWalletData,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    progress,
    setProgress,
    filter,
  } = useContext(WalletContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * get qr list all function call
   */

  const getData = async () => {
    try {
      let orgId = localStorage.getItem("orgId");
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
        orgId,
      };
      const { data } = await Org_Wallet(paramsObj);
      if (data.status === 200) {
        setWalletData(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setWalletData([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy, filter]);

  /**
   * sorting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  return (
    <>
      <Fragment>
        <Breadcrumb parent={WALLET_LIST.PERENT} />
        <Container fluid={true}>
          <TopLoadingBar progress={progress} setProgress={setProgress} />
          <QrListAllItem
            sorting={sorting}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            page={page}
            record={record}
          />
        </Container>
      </Fragment>
    </>
  );
};

export default Wallet;
