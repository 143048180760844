import React, { useContext, useEffect, useState } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, ORG_THEME_MASTER } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import OrgThemeAccessContext from "../../context/OrgThemeAccessContext/OrgThemeAccessContext";
import Select from "react-select";
import { getThemeList } from "../../api/OrgThemeAccessMasterApi/OrgThemeAccessMasterApi";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";
import { Zoom, toast } from "react-toastify";

const OrgThemeAccessMasterAddModel = (props) => {
  const { onSubmit, closeModel } = props;
  const { toggleSubmit, modal, formData, setFormData } = useContext(
    OrgThemeAccessContext
  );
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const [themeData, setThemeData] = useState([]);
  const { loaderShow } = useContext(LoaderContext);

  const getThemeListData = async () => {
    try {
      const paramObj = {
        showAll: true,
      };
      const { data } = await getThemeList(paramObj);
      if (data.status === 200) {
        setThemeData(data.payload.data);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getThemeListData();
  }, []);

  return (
    <Modal size="lg" isOpen={modal}>
      <ModalHeader toggle={() => closeModel()}>
        {toggleSubmit ? (
          <span>{ORG_THEME_MASTER.ADD_PACKAGE}</span>
        ) : (
          <span>
            {ORG_THEME_MASTER.EDIT_PACKAGES} <span> </span>{" "}
            <Edit style={{ width: "50px", height: "20px", color: "#7366ff" }} />
          </span>
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <Row>
                    <Col md="12 mb-4">
                      <Label htmlFor="validationCustom1">
                        {ORG_THEME_MASTER.THEME}
                      </Label>
                      <Select
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        name="aria-live-color"
                        isMulti
                        isClearable
                        isSearchable
                        options={themeData.map((elem) => {
                          return {
                            value: elem.templateId,
                            label: elem.templateName,
                          };
                        })}
                        onChange={(e) =>
                          setFormData((prev) => {
                            return {
                              ...prev,
                              templateIds: [...e],
                            };
                          })
                        }
                        backspaceRemovesValue
                        value={formData.templateIds}
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "30px" }}>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                      disabled={loaderShow === true}
                    >
                      {BUTTON.SAVE}
                    </button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default OrgThemeAccessMasterAddModel;
