import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Download, Eye, Settings, AtSign } from "react-feather";
import { toast, Zoom } from "react-toastify";
import Switch from "react-js-switch";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import {
  AllQrList,
  DownloadQr,
  qrEnableDisable,
} from "../../api/QR List All Api/QrListAllApi";
import OrgContext from "../../context/OrgContext/OrgContext";
import DataNotFound from "../../DataNoteFound/DataNotFound";
import SocketContext from "../../context/SocketContext/SocketContext";
import { useNavigate } from "react-router";
import { userLogout } from "../../auth/Logout";
import { MuiTabelStyle, QR_STATUS } from "../../constant";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import QrRenewModal from "../qrListAll/qrRenewmodal";
import Qrscanmodal from "../qrListAll/qrscanmodal";
import ResetQrPasswordModal from "../qrListAll/resetQrPasswordmodal";

const GenratedQrAndNfc = () => {
  const [formvalue, setformvalue] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [filter, setFilter] = useState("");
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const {
    setModal,
    setRenewModal,
    setQrId,
    setQr,
    setQrTitle,
    qrTitle,
    setResetQrPasswordModal,
  } = useContext(QrListAllContext);
  const { orgId } = useContext(OrgContext);
  const [createdAtFilter, setCreatedAtFilter] = useState("");
  const [qrIdFilter, setQrIdFilter] = useState("");
  const [searchName, setSearchName] = useState("");
  const [expireAtFilter, setExpireAtFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [toDayOrder, setToDayOrder] = useState("");

  const switch_onChange_handle = async (qrId, status) => {
    let formData;
    formData = {
      qrId: qrId,
      status: status === 2 ? 1 : 2,
    };
    try {
      const { data } = await qrEnableDisable(formData);
      if (data.status === 200) {
        status === 1
          ? toast.error(data.message, {
              autoClose: 1000,
              transition: Zoom,
            })
          : toast.success(data.message, {
              autoClose: 1000,
              transition: Zoom,
            });
        getData();
      } else {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        orgId: orgId || localStorage.getItem("orgIdGenQr"),
      };
      if (filter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          title: filter,
        };
      }
      if (createdAtFilter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          createdAt: createdAtFilter,
        };
      }
      if (qrIdFilter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          hwId: qrIdFilter,
        };
      }
      if (searchName === "Month" && expireAtFilter) {
        paramsObj["search"] = {
          ...paramsObj.search,
          ...expireAtFilter,
        };
      }
      if (searchName === "Qr" && statusFilter) {
        paramsObj["search"] = {
          ...paramsObj.search,
          status: +statusFilter,
        };
      }
      if (searchName === "Order" && toDayOrder) {
        paramsObj["search"] = {
          ...paramsObj.search,
          ...toDayOrder,
        };
      }
      if (statusFilter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          status: statusFilter,
        };
      }
      const { data } = await AllQrList(paramsObj);
      if (data.status === 200) {
        setformvalue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
  }, [
    orgId,
    page,
    pageSize,
    filter,
    filter,
    createdAtFilter,
    expireAtFilter,
    qrIdFilter,
    statusFilter,
  ]);

  /**
   * click to plush button open popup function call
   */

  const openModel = async (url, qrId, title) => {
    setQrTitle(title);
    setQrId(qrId);
    setQr(url);
    setModal(true);
  };

  /**
   * download qw function call
   */

  const downloadQr = async (qrId) => {
    await DownloadQr(qrId)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${qrTitle}.png`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        // Calculate the serial number
        return params.api.getRowIndex(params.row.qrId) + 1;
      },
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "title",
      headerName: "TITLE",
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true,
      renderCell: (params) => params.row.qrOrder.title,
    },
    {
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      field: "hwId",
      headerName: "LINK",
      renderCell: (params) => (
        <a
          // href={"https://bytenfc.com/" + params.row.hwId}
          href={
            params.row.qrOrder.orgDetail.qrDomain
              ? "https://" +
                params.row.qrOrder.orgDetail.qrDomain +
                "." +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId
              : "https://" +
                params.row.qrOrder.orgDetail.domain +
                "/" +
                params.row.hwId
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          View Link
        </a>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      field: "cardlogin",
      headerName: "ADMIN LOGIN",
      renderCell: (params) => (
        <a
          href={
            "https://" +
            "admin." +
            params.row.qrOrder.orgDetail.domain +
            "/" +
            params.row.hwId
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {"Login"}
        </a>
      ),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "webData",
      headerName: "EMAIL",
      renderCell: (params) =>
        params?.row?.webData?.userEmail ? params?.row?.webData?.userEmail : "-",
    },
    {
      flex: 0.2,
      minWidth: 100,
      sortable: false,
      field: "domain",
      headerName: "DOMAIN",
      renderCell: (params) => (
        <a
          href={"https://" + params.row?.webData?.webData?.domain}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.row.webData?.webData?.domain || "-"}
        </a>
      ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "name",
      headerName: "CREATED BY",
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true,
      renderCell: (params) => params.row?.qrOrder?.usrDetail?.name,
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "createdAt",
      headerName: "CREATED AT",
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true,
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:mm"),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "expiredAt",
      headerName: "EXPIRED AT",
      renderCell: (params) =>
        moment(params.row.expiredAt).format("DD MMM, YYYY HH:mm"),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "qrStatus",
      headerName: "QR STATUS",
      renderCell: (params) =>
        params.row.qrStatus === QR_STATUS.QR_GENERATED
          ? "NOT REGISTERED"
          : "REGISTERED",
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "renew",
      headerName: "RE-NEW",
      renderCell: (params) =>
        new Date() > new Date(params.row.expiredAt) ? (
          <Button
            onClick={() => {
              setRenewModal(true);
              setQrId(params.row.qrId);
            }}
          >
            Renew
          </Button>
        ) : (
          moment(params.row.expiredAt).format("DD MMM, YYYY HH:mm")
        ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      field: "status",
      headerName: "ACTIVE",
      renderCell: (params) => (
        <Switch
          value={params.row.status === 1 ? true : false}
          onChange={() =>
            switch_onChange_handle(params.row.qrId, params.row.status)
          }
        />
      ),
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <>
          {/* <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            // onClick={() =>
            //   openModel(
            //     params.row.url,
            //     params.row.qrId,
            //     params.row.qrOrder.title
            //   )
            // }
          >
            <Eye style={{ width: "19px" }} />
          </button> */}
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() => {
              setQrId(params.row.qrId);
              setResetQrPasswordModal(true);
            }}
          >
            <Settings style={{ width: "19px" }} />
          </button>
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() =>
              openModel(
                params.row.url,
                params.row.qrId,
                params.row.qrOrder.title
              )
            }
          >
            <Eye style={{ width: "19px" }} />
          </button>
          <button
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "rgb(115, 102, 255)",
            }}
            onClick={() =>
              downloadQrIcon(params.row.qrId, params.row.qrOrder.title)
            }
          >
            <Download style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
  ];

  const downloadQrIcon = async (qrId, title) => {
    await DownloadQr(qrId)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.png`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };
  const handleResetFilters = () => {
    setFilter("");
    setQrIdFilter("");
    setCreatedAtFilter("");
    setExpireAtFilter("");
    setStatusFilter("");
  };

  return (
    <Row className="py-4">
      <QrRenewModal getData={getData} />
      <Qrscanmodal downloadQr={downloadQr} />
      <ResetQrPasswordModal getData={getData} />
      <Col sm="12">
        <Card>
          <CardHeader>
            <h5>{"Qr Order List"}</h5>
          </CardHeader>
          <Table>
            <thead>
              <tr>
                <th>
                  <Row>
                    <Col md="2 mb-2">
                      <Form className="search-file">
                        <Label>Customer</Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="Customer Name Search..."
                          value={filter}
                          onInput={(e) => setFilter(e.target.value)}
                          style={{
                            border: "1px solid #ecf3fa",
                            borderRadius: "8px",
                          }}
                        />
                      </Form>
                    </Col>
                    <Col md="2 mb-2">
                      <Form className="search-file">
                        <Label>QR Id</Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="QR Id Search..."
                          value={qrIdFilter}
                          onInput={(e) => setQrIdFilter(e.target.value)}
                          style={{
                            border: "1px solid #ecf3fa",
                            borderRadius: "8px",
                          }}
                        />
                      </Form>
                    </Col>
                    <Col md="2 mb-2">
                      <Form className="search-file">
                        <Label>Created Date</Label>
                        <Input
                          className="form-control"
                          type="date"
                          placeholder="Created At Search..."
                          value={createdAtFilter}
                          onInput={(e) => setCreatedAtFilter(e.target.value)}
                          style={{
                            border: "1px solid #ecf3fa",
                            borderRadius: "8px",
                          }}
                        />
                      </Form>
                    </Col>
                    <Col md="2 mb-2">
                      <Form className="search-file">
                        <Label>Expire Date</Label>
                        <Input
                          className="form-control"
                          type="date"
                          placeholder="Expire At Search..."
                          value={expireAtFilter}
                          onInput={(e) => setExpireAtFilter(e.target.value)}
                          style={{
                            border: "1px solid #ecf3fa",
                            borderRadius: "8px",
                          }}
                        />
                      </Form>
                    </Col>
                    <Col md="2 mb-2">
                      <Form className="search-file">
                        <Label>Status</Label>
                        <select
                          className="form-control"
                          onChange={(e) => setStatusFilter(e.target.value)}
                          value={statusFilter}
                          name="planName"
                        >
                          <option value="">--select--</option>
                          <option value="1">Active</option>
                          <option value="2">In Active</option>
                        </select>
                      </Form>
                    </Col>
                    <Col md="2 mb-2">
                      <Button
                        onClick={() => handleResetFilters()}
                        className="mt-4 "
                      >
                        Reset Filter
                      </Button>
                    </Col>
                    {/* <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Website Name Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col> */}
                    {/* <Col md="2 mb-2">
                        <Form className="search-file">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="Tenure Search..."
                            value={filter}
                            onInput={(e) => setFilter(e.target.value)}
                            style={{
                              border: "1px solid #ecf3fa",
                              borderRadius: "8px",
                            }}
                          />
                        </Form>
                      </Col> */}
                  </Row>
                </th>
              </tr>
            </thead>
          </Table>
          <Box
            sx={{
              "& .even": {
                backgroundColor: "#f1f1f1",
              },
              "& .odd": {
                backgroundColor: "white",
              },
            }}
          >
            {formvalue.length ? (
              <DataGrid
                autoHeight
                pagination
                sx={MuiTabelStyle}
                rows={formvalue}
                rowCount={record}
                pageSize={pageSize}
                getRowId={(row) => row.qrId}
                columns={defaultColumns}
                page={page - 1}
                rowsPerPageOptions={[7, 10, 25, 50]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                paginationMode="server"
                disableSelectionOnClick
                sortingMode="server"
                checkboxSelection={false}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
                pinnedColumns={["title"]}
              />
            ) : (
              <DataNotFound />
            )}
          </Box>
        </Card>
      </Col>
    </Row>
  );
};

export default GenratedQrAndNfc;
