import React, { useContext } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import CountUp from "react-countup";
import StatuswiseOrder from "./statuswiseOrder";
import { useNavigate } from "react-router";
import AdminDeshboardContext from "../../../context/AdminDeshboardContext/AdminDeshboardContext";
import DashboardCard from "../sellerDeshboard/DashboardCard";

const SupperAdminDeshboard = (props) => {
  const { deshboardData, deshboard } = props;
  const navigate = useNavigate();
  const handleRedirect = async (nfcBal) => {
    navigate(`${process.env.PUBLIC_URL}/organization/organization/Byte_Nfc`);
    setVal(nfcBal);
  };
  const handleRedirect2 = (qrBal) => {
    navigate(`${process.env.PUBLIC_URL}/organization/organization/Byte_Nfc`);
    setVal(qrBal);
  };
  const { setVal } = useContext(AdminDeshboardContext);

  const handleRedirectDashboard = (condition) => {
    if (condition === "org") navigate(`/organization/organization/Byte_Nfc`);
    else navigate(`/orderList/orderList/Byte_Nfc`);
  };

  return (
    <Row className="size-column">
      <Row>
        <Col xl="4" lg="12" md="6" className="box-col-6">
          <Card className="o-hidden">
            <CardBody>
              <div className="ecommerce-widgets media">
                <div className="media-body">
                  <p className="f-w-500 font-roboto">{"Organization Count"}</p>
                  <h4 className="f-w-500 mb-0 f-26">
                    <span className="counter">
                      <CountUp end={deshboardData.orgCount} />
                    </span>
                  </h4>
                </div>
                <div className="ecommerce-box light-bg-primary">
                  <i className="fa fa-heart" aria-hidden="true"></i>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col
          xl="4"
          lg="12"
          md="6"
          className="box-col-6"
          onClick={() => handleRedirectDashboard("org")}
        >
          <Card className="o-hidden">
            <CardBody>
              <div className="ecommerce-widgets media">
                <div className="media-body">
                  <p className="f-w-500 font-roboto">
                    {"Renewal Organization"}
                  </p>
                  <h4 className="f-w-500 mb-0 f-26">
                    <span className="counter">
                      <CountUp end={deshboardData.renewOrgCount} />
                    </span>
                  </h4>
                </div>
                <div className="ecommerce-box light-bg-primary">
                  <i className="fa fa-heart" aria-hidden="true"></i>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <DashboardCard
          lab1={"QR Count"}
          lab2={"Today QR Order"}
          val1={deshboardData.genQrCount}
          val2={deshboardData.todayQrOrder}
        />
        <DashboardCard
          lab1={"NFC Count"}
          lab2={"Today NFC Order"}
          val1={deshboardData.genNfcCount}
          val2={deshboardData.todayNfcOrder}
        />
        <DashboardCard
          lab1={"Expire Organization"}
          lab2={"Expire QR"}
          val1={deshboardData.expireOrgCount}
          val2={deshboardData.expireQrCount}
          event1={() => handleRedirectDashboard("org")}
          event2={() => handleRedirectDashboard("qr")}
        />
        <Col xl="12" lg="12" md="6" className="box-col-6">
          <Card className="o-hidden">
            <CardBody>
              <div className="follow">
                <Row>
                  <Col col="6" className="text-md-end border-end">
                    <p className="f-w-500 font-roboto">{"Zero Nfc Bal"}</p>
                    <h4
                      style={{ cursor: "pointer", color: "#7366ff" }}
                      onClick={() => handleRedirect({ nfcBal: "1" })}
                    >
                      <span>
                        <CountUp end={deshboardData.zeroBalNfc} />
                      </span>
                    </h4>
                  </Col>
                  <Col col="6" className="text-md-start">
                    <p className="f-w-500 font-roboto">{"Zero Qr Bal"}</p>
                    <h4
                      style={{ cursor: "pointer", color: "#7366ff" }}
                      onClick={() => handleRedirect2({ qrBal: "1" })}
                    >
                      <CountUp end={deshboardData.zeroBalQr} />
                    </h4>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
        <StatuswiseOrder deshboard={deshboard} />
      </Row>
    </Row>
  );
};

export default SupperAdminDeshboard;
