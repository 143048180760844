import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { Fragment, useContext } from "react";
import { Edit, Eye, Plus, Grid } from "react-feather";
import Switch from "react-js-switch";
import { useNavigate } from "react-router";

import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Table,
} from "reactstrap";
import { MuiTabelStyle, ORGANIZATION } from "../../constant";
import OrgContext from "../../context/OrgContext/OrgContext";
import { classes } from "../../data/layouts";
import DataNotFound from "../../DataNoteFound/DataNotFound";

import Breadcrumbs from "../../layout/breadcrumb";
import { AccountBalance } from "@material-ui/icons";

const OrganizationItem = (props) => {
  const {
    editHandle,
    showHandle,
    sorting,
    switch_onChange_handle,
    AddBalance,
    AddModel,
    setPage,
    setPageSize,
    pageSize,
    page,
    record,
    showWalletModal,
  } = props;

  const {
    filter,
    formvalue,
    setFilter,
    setOrgID,
    renewAlFilter,
    setRenewAlFilter,
  } = useContext(OrgContext);

  const history = useNavigate();

  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  const handleGenQr = (orgId) => {
    history(
      `${process.env.PUBLIC_URL}/organization/genratedQrAndNfc/${layout}`
    );
    setOrgID(orgId);
    localStorage.setItem("orgIdGenQr", orgId);
  };

  // const handleGenNfc = (orgId) => {
  //   history(`${process.env.PUBLIC_URL}/organization/generatedNfc/${layout}`);
  //   setOrgID(orgId);
  //   localStorage.setItem("orgIdGenNfc", orgId);
  // };

  const handleChangeRenewalFilter = (e) => {
    const { name, value } = e.target;

    setRenewAlFilter((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleResetFilters = () => {
    setRenewAlFilter({
      renewStartDate: "",
      renewEndDate: "",
    });
    setFilter("");
  };

  const defaultColumns = [
    {
      flex: 0.01,
      minWidth: 100,
      field: "sNo",
      headerName: "S.No.",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      minWidth: 150,
      renderCell: (params) => (
        <>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              // color: "#51bb25",
              color: "#7366ff",
            }}
            onClick={() => editHandle(params.row.orgId)}
          >
            <Edit style={{ width: "19px" }} />
          </button>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() => showHandle(params.row.orgId)}
          >
            <Eye style={{ width: "19px" }} />
          </button>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              color: "#7366ff",
            }}
            onClick={() =>
              showWalletModal(params.row.orgId, params.row.balance)
            }
          >
            <AccountBalance style={{ width: "19px" }} />
          </button>
          <button
            className="_edit"
            type="button"
            style={{
              background: "none",
              textDecoration: "none",
              border: "none",
              // color: "#51bb25",
              color: "#7366ff",
            }}
            onClick={() =>
              history(
                `${process.env.PUBLIC_URL}/organization/${params.row.orgId}/theme-access/${layout}`
              )
            }
          >
            <Grid style={{ width: "19px" }} />
          </button>
        </>
      ),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "orgName",
      headerName: "ORGANIZATION NAME",
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "domain",
      headerName: "DOMAIN",
    },
    // {
    //   flex: 0.1,
    //   minWidth: 250,
    //   field: "email",
    //   headerName: "EMAIL",
    // },
    {
      flex: 0.1,
      minWidth: 140,
      field: "sslRenewDate",
      headerName: "SSL RENEW DATE",
      renderCell: (params) =>
        moment(params.row.sslRenewDate).format("DD-MM-YYYY"),
    },
    {
      flex: 0.1,
      minWidth: 140,
      field: "qrPlanHistories",
      headerName: "SELLER RENEW DATE",
      renderCell: (params) =>
        params.row.qrPlanHistories?.length
          ? moment(params.row.qrPlanHistories[0]).format("DD-MM-YYYY")
          : "-",
    },
    {
      flex: 0.1,
      minWidth: 140,
      field: "expireQrCount",
      headerName: "EXPIRE QR",
      renderCell: (params) => params.row.expireQrCount,
    },
    {
      flex: 0.1,
      minWidth: 140,
      field: "total",
      headerName: "TOTAL QR",
      renderCell: (params) =>
        Number(params.row.genQr) +
        Number(params.row.genNfc) +
        Number(params.row.qrBal) +
        Number(params.row.nfcBal),
    },
    // {
    //   flex: 0.1,
    //   minWidth: 80,
    //   field: "nfcBal",
    //   headerName: "NFC BAL",
    // },
    {
      flex: 0.1,
      minWidth: 80,
      field: "qrBal",
      headerName: "QR BAL",
    },
    {
      flex: 0.4,
      minWidth: 80,
      field: "wakketbalance",
      headerName: "WALLENT",
      renderCell: (params) => params.row.balance,
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "genQr",
      headerName: "GENERATED QR",
      renderCell: (params) =>
        params.row.genQr ? (
          <p
            style={{ color: "#7366ff", margin: "0", cursor: "pointer" }}
            onClick={() => handleGenQr(params.row.orgId)}
          >
            {params.row.genQr}
          </p>
        ) : (
          "--"
        ),
    },
    // {
    //   flex: 0.1,
    //   minWidth: 180,
    //   field: "genNfc",
    //   headerName: "GENERATED NFC",
    //   renderCell: (params) =>
    //     params.row.genNfc ? (
    //       <p
    //         style={{ color: "#7366ff", margin: "0", cursor: "pointer" }}
    //         onClick={() => handleGenNfc(params.row.orgId)}
    //       >
    //         {params.row.genNfc}
    //       </p>
    //     ) : (
    //       "--"
    //     ),
    // },
    {
      flex: 0.1,
      minWidth: 180,
      field: "contect",
      headerName: "PHONE",
      renderCell: (params) => (
        <>
          {" "}
          <span>{params.row.dialCode}</span> <span>{params.row.phone}</span>{" "}
        </>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "city",
      headerName: "CITY",
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "isActive",
      headerName: "STATUS",
      renderCell: (params) => (
        <Switch
          value={params.row.isActive ? true : false}
          onChange={() =>
            switch_onChange_handle(params.row.orgId, params.row.isActive)
          }
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "balance",
      headerName: "BALANCE",
      renderCell: (params) => (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => AddBalance(params.row.orgId)}
        >
          {"Balance"}
        </button>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CREATED AT",
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY HH:mm"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "expiredAt",
      headerName: "EXPIRED AT",
      renderCell: (params) =>
        moment(params.row.expiredAt).format("DD MMM, YYYY HH:mm"),
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs parent={ORGANIZATION.PERENT} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3>{ORGANIZATION.PERENT}</h3>
                <button
                  type="button"
                  style={{ border: "none", background: "none" }}
                  onClick={AddModel}
                >
                  <div
                    style={{
                      width: "50px",
                      background: "#7366ff",
                      borderRadius: "19rem",
                      color: "white",
                    }}
                  >
                    <h1>
                      <Plus />
                    </h1>
                  </div>
                </button>
              </CardHeader>
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Row>
                        <Col md="2 mb-2">
                          <Form className="search-file">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="Org Name Search..."
                              value={filter}
                              onInput={(e) => setFilter(e.target.value)}
                              style={{
                                border: "1px solid #ecf3fa",
                                borderRadius: "8px",
                              }}
                            />
                          </Form>
                        </Col>
                      </Row>
                      <Row>
                        <h6 className="mx-3 mt-2 mb-3">Renewal Filter</h6>
                        <Col md="2 mb-2">
                          <Form className="search-file">
                            <Input
                              className="form-control"
                              type="date"
                              name="renewStartDate"
                              placeholder="Org Name Search..."
                              value={renewAlFilter.renewStartDate}
                              onInput={(e) => handleChangeRenewalFilter(e)}
                              style={{
                                border: "1px solid #ecf3fa",
                                borderRadius: "8px",
                              }}
                            />
                          </Form>
                        </Col>
                        <Col md="2 mb-2">
                          <Form className="search-file">
                            <Input
                              className="form-control"
                              type="date"
                              name="renewEndDate"
                              placeholder="Org Name Search..."
                              value={renewAlFilter.renewEndDate}
                              onInput={(e) => handleChangeRenewalFilter(e)}
                              style={{
                                border: "1px solid #ecf3fa",
                                borderRadius: "8px",
                              }}
                            />
                          </Form>
                        </Col>
                        <Col md="2 mb-2">
                          <Button onClick={() => handleResetFilters()}>
                            Reset Filter
                          </Button>
                        </Col>
                      </Row>
                    </th>
                  </tr>
                </thead>
              </Table>
              <Box
                sx={{
                  "& .even": {
                    backgroundColor: "#f1f1f1",
                  },
                  "& .odd": {
                    backgroundColor: "white",
                  },
                }}
              >
                {formvalue.length && formvalue.length ? (
                  <>
                    <DataGrid
                      autoHeight
                      pagination
                      sx={MuiTabelStyle}
                      rows={formvalue}
                      rowCount={record}
                      pageSize={pageSize}
                      getRowId={(row) => row.orgId}
                      columns={defaultColumns}
                      page={page - 1}
                      rowsPerPageOptions={[7, 10, 25, 50]}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                      paginationMode="server"
                      disableSelectionOnClick
                      checkboxSelection={false}
                      sortingMode="server"
                      onSortModelChange={sorting}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                      }
                    />
                  </>
                ) : (
                  <DataNotFound />
                )}
              </Box>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default OrganizationItem;
