import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { CHANGE_PASSWORD } from "../../constant";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import { ResetQrPassword } from "../../api/QR List All Api/QrListAllApi";
import { Zoom, toast } from "react-toastify";
import { userLogout } from "../../auth/Logout";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";

const ResetQrPasswordModal = (props) => {
  const { resetQrPasswordModal, setResetQrPasswordModal, setQrId, qrId } =
    useContext(QrListAllContext);
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const history = useNavigate();
  const { socket } = useContext(SocketContext);

  const handleEmpty = () => {
    setQrId("");
    setFormData({ password: "", confirmPassword: "" });
    setResetQrPasswordModal(false);
  };

  const changeInput = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let newData = {
        ...formData,
      };
      if (formData.password === "") {
        toast.error("password is required", {
          autoClose: 1000,
          transition: Zoom,
        });
        return;
      } else if (formData.password !== formData.confirmPassword) {
        toast.error("password & confirm password are not match!", {
          autoClose: 1000,
          transition: Zoom,
        });
        return;
      }
      const { data } = await ResetQrPassword({ formData: newData, qrId });
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        handleEmpty();
        // getData();
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  return (
    <Modal size="lg" isOpen={resetQrPasswordModal} toggle={() => handleEmpty()}>
      <ModalHeader toggle={() => handleEmpty()}>{"Reset password"}</ModalHeader>
      <ModalBody>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Row>
            <Col md="6 mb-4">
              <Label htmlFor="validationCustom08">
                {CHANGE_PASSWORD.NEW_PASSWORD}
              </Label>
              <Input
                className="form-control"
                type="password"
                onChange={(e) => changeInput(e)}
                value={formData.password}
                name="password"
              />
            </Col>
            <Col md="6 mb-4">
              <Label htmlFor="validationCustom08">
                {CHANGE_PASSWORD.CONFIRM_PASSWORD}
              </Label>
              <Input
                className="form-control"
                type="password"
                onChange={(e) => changeInput(e)}
                value={formData.confirmPassword}
                name="confirmPassword"
              />
            </Col>
          </Row>
          <Button type="submit">Submit</Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ResetQrPasswordModal;
