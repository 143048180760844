import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast, Zoom } from "react-toastify";
import { Container } from "reactstrap";
import { NFC_ORDER_LIST, USER_ROLE } from "../../constant";
import Breadcrumb from "../../layout/breadcrumb";
import CreateNfc from "./createNfc";
import NfcOrderListItem from "./nfcOrderListItem";
import {
  Add_Nfc,
  NfcOrderList,
  Nfc_Order_List_Details,
  Nfc_Order_List_Title_Update,
  Nfc_Regenarate,
} from "../../api/Nfc Order List Api/NfcOrderListApi";
import TitleUpdate from "./titleUpdate";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import NfcOrderListContext from "../../context/nfcOrderListContext/NfcOrderListContext";
import { useNavigate } from "react-router";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { tableId } from "../../utils/helper";
import { userLogout } from "../../auth/Logout";
import SocketContext from "../../context/SocketContext/SocketContext";

const OrderList = () => {
  const {
    setNfcOrder,
    qty,
    setQty,
    title,
    setTitle,
    nfcOrderId,
    setNfcOrderID,
    frontImg,
    setFrontImg,
    check,
    setCheck,
    setEditTitle,
    editTitle,
    setNfcModal,
    nfcmodal,
    setIsHovering,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    progress,
    setProgress,
    nfcorderValue,
  } = useContext(NfcOrderListContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * nfc order list function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await NfcOrderList(paramsObj);
      if (data.status === 200) {
        setNfcOrder(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setNfcOrder([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy]);

  tableId(page, pageSize, nfcorderValue);

  /**
   * sorting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /**
   * empty feild function call
   */

  const emptyFeild = () => {
    setQty("");
    setTitle("");
    setCheck("");
    setFrontImg([]);
    setLoaderShow(false);
  };

  /**
   * fill feild function call
   */

  const fillFeild = () => {
    setQty(qty);
    setTitle(title);
    setCheck(check);
    setFrontImg([frontImg]);
    setLoaderShow(false);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const layout = localStorage.getItem("layout");

  const navigate = useNavigate();

  /**
   * create nfc function call
   */

  const createNfc = async (e) => {
    e.preventDefault();
    let formData2 = new FormData();
    setLoaderShow(!loaderShow);
    formData2.append("frontImg", frontImg);
    formData2.append("qty", qty);
    formData2.append("title", title);
    formData2.append("check", check);
    try {
      const { data } = await Add_Nfc(formData2);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        navigate(`${process.env.PUBLIC_URL}/nfcList/nfcList/${layout}`);
        setNfcModal(false);
        emptyFeild();
        getData();
      } else {
        fillFeild();
      }
    } catch (error) {
      setLoaderShow(false);
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * handle regerete nfc function call
   */

  const handleRegenerate = async (nfcOrderId) => {
    let fromData;
    fromData = {
      nfcOrderId: nfcOrderId,
    };
    try {
      const { data } = await Nfc_Regenarate(fromData);
      if (data.status === 200) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        getData();
      } else {
        setNfcOrder([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message);
    }
  };

  /**
   * nfc order edit function call
   */

  const handleEdit = async (nfcOrderId) => {
    setNfcOrderID(nfcOrderId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Nfc_Order_List_Details(nfcOrderId);
      if (data.status === 200) {
        setEditTitle(true);
        setTitle(data.payload.data.title);
        setLoaderShow(false);
      } else {
        setNfcOrder([]);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message);
    }
  };

  /**
   * nfc order title update function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoaderShow(!loaderShow);
    let formData;
    formData = {
      title: title,
    };

    try {
      const { data } = await Nfc_Order_List_Title_Update(nfcOrderId, formData);
      if (data.status === 200) {
        setEditTitle(false);
        getData();
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setTitle("");
        setLoaderShow(false);
      } else {
        setTitle(title);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * edit title model close function call
   */

  const editTitleModel = () => {
    setTitle("");
    setEditTitle(!editTitle);
  };

  /**
   * nfc model close function call
   */

  const nfcModelClose = () => {
    setQty("");
    setTitle("");
    setCheck("");
    setFrontImg([]);
    setNfcModal(!nfcmodal);
    setLoaderShow(false);
  };

  return (
    <>
      <Fragment>
        <Breadcrumb parent={NFC_ORDER_LIST.PERENT} />
        <Container fluid={true}>
          <TopLoadingBar progress={progress} setProgress={setProgress} />
          <TitleUpdate onSubmit={onSubmit} editTitleModel={editTitleModel} />
          {USER_ROLE.ADMIN === +localStorage.getItem("roleId") ? (
            <div>
              <NfcOrderListItem
                handleMouseOut={handleMouseOut}
                handleMouseOver={handleMouseOver}
                sorting={sorting}
                handleEdit={handleEdit}
                pageSize={pageSize}
                page={page}
                record={record}
                setPageSize={setPageSize}
                setPage={setPage}
              />
            </div>
          ) : (
            <div>
              <CreateNfc createNfc={createNfc} nfcModelClose={nfcModelClose} />
              <NfcOrderListItem
                handleMouseOut={handleMouseOut}
                handleMouseOver={handleMouseOver}
                sorting={sorting}
                handleRegenerate={handleRegenerate}
                handleEdit={handleEdit}
                pageSize={pageSize}
                page={page}
                record={record}
                setPageSize={setPageSize}
                setPage={setPage}
              />
            </div>
          )}
        </Container>
      </Fragment>
    </>
  );
};

export default OrderList;
