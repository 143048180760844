import React, { useState } from "react";
import SocketContext from "./SocketContext";
import { io } from "socket.io-client";
import { SOCKET_EVENT } from "../../constant";

const SocketState = (props) => {
  const [socket, setSocket] = useState(null);
  const [qrModal, setQrModal] = useState(false);
  const [qr, setQr] = useState("");
  const [isConnect, setIsConnect] = useState(false);

  const connect = (token) => {
    if (token) {
      const socket = io(process.env.REACT_APP_SOCKET_IO, {
        // Pass headers in the extra configuration object
        extraHeaders: {
          Authorization: localStorage.getItem("token"),
        },
        path: "/chat-socket/",
        transports: ["websocket"],
        query: {
          authorization: localStorage.getItem("token"),
        },
        // auth: localStorage.getItem("token"),
      });
      setSocket(socket);
      return socket;
    } else if (socket) {
      socket?.disconnect();
      setSocket(null);
    }
  };

  // Function to close WebSocket connection
  const disconnect = () => {
    if (socket) {
      socket.close();
      setSocket(null);
    }
  };

  const reConnect = () => {
    if (socket) {
      socket.emit("message", { code: SOCKET_EVENT.CONNECT });
      document.getElementById("show-whatsapp-send-loader").style.zIndex = 1;
    }
  };
  return (
    <SocketContext.Provider
      value={{
        socket,
        disconnect,
        connect,
        qrModal,
        setQrModal,
        qr,
        setQr,
        reConnect,
        isConnect,
        setIsConnect,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketState;
