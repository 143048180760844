import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast, Zoom } from "react-toastify";
import Breadcrumb from "../../layout/breadcrumb";
import { Container } from "reactstrap";
import QrListAllItem from "./qrListAllItem";
import Qrscanmodal from "./qrscanmodal";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import { AllQrList, DownloadQr } from "../../api/QR List All Api/QrListAllApi";
import QrListAllContext from "../../context/QrListAllContext/QrListAllContext";
import { QR_LIST } from "../../constant";
import { tableId } from "../../utils/helper";
import QrRenewModal from "./qrRenewmodal";
import ResetQrPasswordModal from "./resetQrPasswordmodal";
import ChangeDomainModal from "./changeDomainModal";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";

const QrListAll = () => {
  const {
    setQrAll,
    setQr,
    setQrId,
    qrTitle,
    setQrTitle,
    setIsHovering,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    setModal,
    progress,
    setProgress,
    filter,
    qrAll,
    createdAtFilter,
    expireAtFilter,
    qrIdFilter,
    statusFilter,
    toDayOrder,
    searchName,
  } = useContext(QrListAllContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * get qr list all function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      if (filter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          title: filter,
        };
      }
      if (createdAtFilter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          createdAt: createdAtFilter,
        };
      }
      if (qrIdFilter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          hwId: qrIdFilter,
        };
      }
      if (searchName === "Month" && expireAtFilter) {
        paramsObj["search"] = {
          ...paramsObj.search,
          ...expireAtFilter,
        };
      }
      if (searchName === "Qr" && statusFilter) {
        paramsObj["search"] = {
          ...paramsObj.search,
          status: +statusFilter,
        };
      }
      if (searchName === "Order" && toDayOrder) {
        paramsObj["search"] = {
          ...paramsObj.search,
          ...toDayOrder,
        };
      }
      if (statusFilter.length) {
        paramsObj["search"] = {
          ...paramsObj.search,
          status: statusFilter,
        };
      }
      const { data } = await AllQrList(paramsObj);
      if (data.status === 200) {
        setQrAll(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setQrAll([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [
    page,
    pageSize,
    sortOrder,
    sortBy,
    filter,
    createdAtFilter,
    expireAtFilter,
    qrIdFilter,
    statusFilter,
  ]);

  tableId(page, pageSize, qrAll);

  /**
   * sorting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  /**
   * click to plush button open popup function call
   */

  const openModel = async (url, qrId, title) => {
    setQrTitle(title);
    setQrId(qrId);
    setQr(url);
    setModal(true);
  };

  /**
   * download qw function call
   */

  const downloadQr = async (qrId) => {
    await DownloadQr(qrId)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${qrTitle}.png`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };

  /**
   * download qr to function call
   */

  const downloadQrIcon = async (qrId, url, title) => {
    await DownloadQr(qrId)
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.png`);
        document.body.appendChild(link);
        link.click();
        toast.success("Image Download Successfully", {
          toastId: "imageSuccess",
          autoClose: 1000,
          transition: Zoom,
        });
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error("Image Download Failed", {
          toastId: "imageFailed",
          autoClose: 1000,
          transition: Zoom,
        });
      });
  };
  return (
    <>
      <Fragment>
        <Breadcrumb parent={QR_LIST.PERENT} />
        <Container fluid={true}>
          <TopLoadingBar progress={progress} setProgress={setProgress} />
          <Qrscanmodal downloadQr={downloadQr} />
          <QrRenewModal getData={getData} />
          <ResetQrPasswordModal getData={getData} />
          <ChangeDomainModal getData={getData} />
          <QrListAllItem
            handleMouseOut={handleMouseOut}
            handleMouseOver={handleMouseOver}
            sorting={sorting}
            openModel={openModel}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
            page={page}
            record={record}
            downloadQrIcon={downloadQrIcon}
            getData={getData}
          />
        </Container>
      </Fragment>
    </>
  );
};

export default QrListAll;
