import axios from "axios";
import { CHNAGE_EMAIL, CHNAGE_PASS } from "../../constant/API constant";

export const Change_Pass = (formData) => {
  return axios.post(
    `${process.env.REACT_APP_WHITE_LABEL_API_URL}${CHNAGE_PASS}`,
    formData,
    {
      headers: { token: "8ef4ec1b-cc13-4caa-a312-36927035cc5e" },
    }
  );
};
export const Change_Email = (formData) => {
  return axios.post(
    `${process.env.REACT_APP_WHITE_LABEL_API_URL}${CHNAGE_EMAIL}`,
    formData,
    {
      headers: { token: "8ef4ec1b-cc13-4caa-a312-36927035cc5e" },
    }
  );
};
