import axios from "axios";
import {
  ALL_QR,
  ALL_QR_STATUS_CHANGE,
  CHANGE_DOMAIN_NAME,
  CHECK_DOMAIN,
  DOWNLOAD_QR,
  QR_RESET_PASSWORD,
  RENEW_QR,
} from "../../constant/API constant";

export const AllQrList = (paramsObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${ALL_QR}`, {
    headers: { Authorization: jwt_token },
    params: paramsObj,
  });
};

export const DownloadQr = (qrId) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${DOWNLOAD_QR}`, {
    headers: { Authorization: jwt_token, "Content-Type": "image/png" },
    params: { qrId: qrId },
    responseType: "blob",
  });
};

export const QrRenewal = ({ formData, qrId }) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(`${process.env.REACT_APP_API_URL}${RENEW_QR}`, formData, {
    headers: { Authorization: jwt_token },
    params: { qrId },
  });
};
export const ResetQrPassword = ({ formData, qrId }) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${QR_RESET_PASSWORD}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { qrId },
    }
  );
};

/**
 * get check domain
 * @param {Parameters} paramObj
 * @returns
 */
export const Check_Sub_Domain = (paramObj) => {
  const jwt_token = localStorage.getItem("token");
  return axios.get(`${process.env.REACT_APP_API_URL}${CHECK_DOMAIN}`, {
    headers: { Authorization: jwt_token },
    params: paramObj,
  });
};

/**
 * update domain name
 * @param {Parameters} paramObj
 * @returns
 */
export const updateDomainName = (formData) => {
  const jwt_token = localStorage.getItem("token");
  const { domain, ...restForm } = formData;
  let domainParam = {
    domain,
  };
  if ("subDomain" in formData) {
    domainParam["subDomain"] = formData.subDomain;
  }
  return axios.put(
    `${process.env.REACT_APP_API_URL}${CHANGE_DOMAIN_NAME}`,
    { ...restForm, domain },
    {
      headers: { Authorization: jwt_token },
      // params: domainParam,
    }
  );
};

export const qrEnableDisable = (formData) => {
  const jwt_token = localStorage.getItem("token");
  return axios.put(
    `${process.env.REACT_APP_API_URL}${ALL_QR_STATUS_CHANGE}`,
    formData,
    {
      headers: { Authorization: jwt_token },
      params: { qrId: formData.qrId },
    }
  );
};
