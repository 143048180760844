import React, { Fragment, useEffect, useContext, useState } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import { toast, Zoom } from "react-toastify";
import ShipingMasterItem from "./shipingMasterItem";
import ShippingAddModel from "./shippingAddModel";
import {
  Shiping_Edit,
  ShippingList,
  Shipping_Add,
  Shipping_Details,
} from "../../api/Shipping Api/AhippingApi";
import ShippingMasterContext from "../../context/ShippingMasterContext/ShippingMasterContext";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import { SHIPPING_MASTER } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import { tableId } from "../../utils/helper";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";

const Shoppingmaster = () => {
  const {
    setShippValue,
    shippingId,
    setshippingId,
    companyName,
    setCompanyName,
    trackingLink,
    setTrackingLink,
    editIndex,
    seteditIndex,
    setModal,
    modal,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    progress,
    setProgress,
    toggleSubmit,
    setToggleSubmit,
    shippValue,
  } = useContext(ShippingMasterContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);

  /**
   * get shipping master to function call
   */

  const getData = async () => {
    try {
      let paramsObj = {
        pageNumber: page,
        recordsPerPage: pageSize,
        sortOrder,
        sortBy,
      };
      const { data } = await ShippingList(paramsObj);
      if (data.status === 200) {
        setShippValue(data.payload.data);
        setRecord(data.pager.totalRecords);
      } else {
        setShippValue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize, sortOrder, sortBy]);
  tableId(page, pageSize, shippValue);

  /**
   * click to pulse button open popup function call
   */

  const AddModel = () => {
    setModal(true);
    setToggleSubmit(true);
  };

  /**
   * edit shipping master function call
   */

  const editHandle = async (shippingId) => {
    setshippingId(shippingId);
    setLoaderShow(!loaderShow);
    try {
      const { data } = await Shipping_Details(shippingId);
      if (data.status === 200) {
        setToggleSubmit(false);
        seteditIndex(shippingId);
        setModal(true);

        setCompanyName(data.payload.data.companyName);
        setTrackingLink(data.payload.data.trackingLink);
        setLoaderShow(false);
      }
    } catch (error) {
      setLoaderShow(false);
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };

  /**
   * empty feild function call
   */

  const emptyFeild = () => {
    setCompanyName("");
    setTrackingLink("");
    setshippingId("");
    setLoaderShow(false);
  };

  /**
   * fill feild function call
   */

  const fillFeild = () => {
    setCompanyName(companyName);
    setTrackingLink(trackingLink);
    setshippingId(shippingId);
    setLoaderShow(false);
  };

  /**
   * create and edit shipping master and function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;
    if (editIndex > -1 && !toggleSubmit) {
      setLoaderShow(!loaderShow);
      formData = {
        shippingId: shippingId,
        companyName: companyName,
        trackingLink: trackingLink,
      };

      try {
        const { data } = await Shiping_Edit(formData, shippingId);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = {
        companyName: companyName,
        trackingLink: trackingLink,
      };

      try {
        const { data } = await Shipping_Add(formData);
        if (data.status === 200) {
          toast.success(data.message, {
            autoClose: 1000,
          });
          setModal(false);
          setToggleSubmit(true);
          getData();
          emptyFeild();
        } else {
          fillFeild();
        }
      } catch (error) {
        setLoaderShow(false);
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * sorting function call
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  /** closre popup function call */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  return (
    <Fragment>
      <Breadcrumbs parent={SHIPPING_MASTER.PERENT} />
      <Container fluid={true}>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <ShippingAddModel onSubmit={onSubmit} closeModel={closeModel} />
        <ShipingMasterItem
          editHandle={editHandle}
          sorting={sorting}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
        />
      </Container>
    </Fragment>
  );
};

export default Shoppingmaster;
