import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, ORGANIZATION, PACKAGES } from "../../constant";
import PackagesContext from "../../context/PackagesContext/PackagesContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";

const PackageAddModel = (props) => {
  const { onSubmit, closeModel } = props;

  const {
    name,
    setName,
    price,
    setPrice,
    gst,
    setGst,
    type,
    setType,
    setOrgId,
    formvalue,
    benefit,
    setBenefit,
    qty,
    setQty,
    toggleSubmit,
    orgId,
    modal,
  } = useContext(PackagesContext);

  const { loaderShow } = useContext(LoaderContext);

  return (
    <Modal size="lg" isOpen={modal}>
      <ModalHeader toggle={() => closeModel()}>
        {toggleSubmit ? (
          <span>{PACKAGES.ADD_PACKAGE}</span>
        ) : (
          <span>
            {PACKAGES.EDIT_PACKAGES} <span> </span>{" "}
            <Edit style={{ width: "50px", height: "20px", color: "#7366ff" }} />
          </span>
        )}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody style={{ padding: "0" }}>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <Row>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom1">{PACKAGES.NAME}</Label>
                      <Input
                        className="form-control"
                        name="name"
                        id="validationCustom1"
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom2">
                        {PACKAGES.PRICE}
                      </Label>
                      <Input
                        className="form-control"
                        name="price"
                        id="validationCustom2"
                        type="number"
                        placeholder="Price"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3 mb-4">
                      <Label htmlFor="validationCustom3">{PACKAGES.GST}</Label>
                      <Input
                        className="form-control"
                        name="gst"
                        id="validationCustom3"
                        type="number"
                        placeholder="GST"
                        value={gst}
                        onChange={(e) => setGst(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="3 mb-4">
                      <Label htmlFor="validationCustom4">{PACKAGES.QTY}</Label>
                      <Input
                        className="form-control"
                        name="qty"
                        id="validationCustom4"
                        type="number"
                        placeholder="QTY"
                        value={qty}
                        onChange={(e) => setQty(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom5">{PACKAGES.TYPE}</Label>
                      <select
                        className="form-select"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option>--select--</option>
                        <option value="nfc">{PACKAGES.QTY}</option>
                        <option value="qr">{PACKAGES.QR}</option>
                      </select>
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom6">
                        {ORGANIZATION.PERENT}
                      </Label>
                      <select
                        className="form-select"
                        name="orglist"
                        onChange={(e) => setOrgId(e.target.value)}
                      >
                        <option value="">--select--</option>
                        {formvalue.map((elem) => {
                          return (
                            <option
                              key={elem.orgId}
                              value={elem.orgId}
                              selected={elem.orgId === orgId ? "selected" : ""}
                            >
                              {elem.orgName}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                    <Col md="6 mb-4">
                      <Label htmlFor="validationCustom7">
                        {PACKAGES.BENEFIT}
                      </Label>
                      <Input
                        className="form-control"
                        name="benefit"
                        id="validationCustom7"
                        type="text"
                        placeholder="Benefit"
                        value={benefit}
                        onChange={(e) => setBenefit(e.target.value)}
                        required
                      />
                      <div className="invalid-feedback">
                        {"Please provide a valid Admin Name."}
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: "30px" }}>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      value="Submit"
                      disabled={loaderShow === true}
                    >
                      {BUTTON.SAVE}
                    </button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PackageAddModel;
