import React, { useContext } from "react";
import { Edit } from "react-feather";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, WHITE_LABEL_DOMAIN_CONSTANT } from "../../constant";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import WhiteLabelDomainContext from "../../context/WhiteLabelDomainContext/WhiteLabelDomainContext";

const WhiteLabelDomainAddModel = (props) => {
  const { onSubmit, closeModel, readUrl } = props;

  const {
    modal,
    whitelabelDomain,
    setWhiteLabelDomain,
    name,
    setName,
    url,
    setUrl,
    togglesubmit,
  } = useContext(WhiteLabelDomainContext);

  const { loaderShow } = useContext(LoaderContext);

  return (
    <>
      <Modal size="xl" isOpen={modal}>
        <ModalHeader toggle={() => closeModel()}>
          {togglesubmit ? (
            <span>{WHITE_LABEL_DOMAIN_CONSTANT.ADD_WHITE_LABEL_DOAMIN}</span>
          ) : (
            <span>
              {WHITE_LABEL_DOMAIN_CONSTANT.EDIT_WHITE_LABEL_DOAMIN}
              <span> </span>
              <Edit
                style={{ width: "50px", height: "20px", color: "#7366ff" }}
              />
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>
                    {WHITE_LABEL_DOMAIN_CONSTANT.WHITE_LABEL_DOMAIN_DETAILS}
                  </h5>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => onSubmit(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom01">
                          {WHITE_LABEL_DOMAIN_CONSTANT.WHITE_LABEL_DOMAIN}
                        </Label>
                        <Input
                          className="form-control"
                          name="fristName"
                          type="text"
                          placeholder={
                            WHITE_LABEL_DOMAIN_CONSTANT.WHITE_LABEL_DOMAIN
                          }
                          id="validationCustom01"
                          onChange={(e) => setWhiteLabelDomain(e.target.value)}
                          value={whitelabelDomain}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid ORG Name."}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom02">
                          {WHITE_LABEL_DOMAIN_CONSTANT.NAME}
                        </Label>
                        <Input
                          className="form-control"
                          name="name"
                          type="text"
                          id="validationCustom02"
                          placeholder={WHITE_LABEL_DOMAIN_CONSTANT.NAME}
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Email."}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6 mb-4">
                        <Label htmlFor="validationCustom20">
                          {WHITE_LABEL_DOMAIN_CONSTANT.LOGO}
                        </Label>
                        <Input
                          className="form-control"
                          type="file"
                          name="logo"
                          id="validationCustome20"
                          placeholder={WHITE_LABEL_DOMAIN_CONSTANT.LOGO}
                          onChange={(e) => readUrl(e)}
                          // value={logo}
                          required
                        />
                      </Col>
                      <Col md="6" mb="4">
                        <Label htmlFor="validationCustom03">
                          {WHITE_LABEL_DOMAIN_CONSTANT.FAVICON}
                        </Label>
                        <Input
                          className="form-control"
                          type="file"
                          name="favicon"
                          id="validationCustome03"
                          placeholder={WHITE_LABEL_DOMAIN_CONSTANT.FAVICON}
                          onChange={(e) => readUrl(e)}
                          // value={favicon}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12 mb-4">
                        <Label htmlFor="validationCustom06">
                          {WHITE_LABEL_DOMAIN_CONSTANT.URL}
                        </Label>
                        <Input
                          className="form-control"
                          name="url"
                          id="validationCustom06"
                          type="text"
                          placeholder={WHITE_LABEL_DOMAIN_CONSTANT.URL}
                          onChange={(e) => setUrl(e.target.value)}
                          value={url}
                          required
                        />
                        <div className="invalid-feedback">
                          {"Please provide a valid Address 1."}
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ paddingTop: "30px" }}>
                      <button
                        className="btn btn-primary"
                        type="submit"
                        value="Submit"
                        disabled={loaderShow === true}
                      >
                        {BUTTON.SUBMIT}
                      </button>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default WhiteLabelDomainAddModel;
