import * as React from "react";

import { useState } from "react";
import QrListAllContext from "./QrListAllContext";

const QrListAllState = (props) => {
  const [qrAll, setQrAll] = useState([]);

  const [qr, setQr] = useState("");
  const [qrId, setQrId] = useState("");
  const [qrTitle, setQrTitle] = useState("");
  const [filter, setFilter] = useState("");
  const [createdAtFilter, setCreatedAtFilter] = useState("");
  const [expireAtFilter, setExpireAtFilter] = useState("");
  const [toDayOrder, setToDayOrder] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [qrIdFilter, setQrIdFilter] = useState("");

  const [totalRecord, setTotalRecord] = useState(); //TOTAL RECORD -->
  const [pageNumber, setpageNumber] = useState(1); //PAGINATION -->
  const [recordsPerPage, setRecordsPerPage] = useState(10); //PAGINATION -->
  const [isHovering, setIsHovering] = useState(false); //HOVWER SORTING -->
  const [sortOrder, setSortOrder] = useState(""); //SORTING -->
  const [sortBy, setSortBy] = useState("createdAt"); //SORTING -->
  const [domain, setDomain] = useState("");
  const [domainDetails, setDomainDetails] = useState("");

  const [modal, setModal] = useState(false);
  const [renewModal, setRenewModal] = useState(false);
  const [resetQrPasswordModal, setResetQrPasswordModal] = useState(false);
  const [changeDomainModal, setChangeDomainModal] = useState(false);
  const [progress, setProgress] = useState(0);

  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [solutionDetails, setSolutionDetails] = useState([]);
  const [planAmount, setPlanAmount] = useState("");
  const [tests, setTests] = useState([]);
  const [planName, setPlanName] = useState("");
  const [planMasterId, setPlanMasterId] = useState("");
  const [planValidity, setPlanValidity] = useState("");

  return (
    <>
      <QrListAllContext.Provider
        value={{
          qrAll,
          setQrAll,
          qr,
          setQr,
          qrId,
          setQrId,
          qrTitle,
          setQrTitle,
          totalRecord,
          setTotalRecord,
          pageNumber,
          setpageNumber,
          recordsPerPage,
          setRecordsPerPage,
          isHovering,
          setIsHovering,
          sortOrder,
          setSortOrder,
          sortBy,
          setSortBy,
          modal,
          setModal,
          progress,
          setProgress,
          isHovering1,
          setIsHovering1,
          isHovering2,
          setIsHovering2,
          isHovering3,
          setIsHovering3,
          filter,
          setFilter,
          createdAtFilter,
          setCreatedAtFilter,
          expireAtFilter,
          setExpireAtFilter,
          statusFilter,
          setStatusFilter,
          qrIdFilter,
          setQrIdFilter,
          renewModal,
          setRenewModal,
          resetQrPasswordModal,
          setResetQrPasswordModal,
          domain,
          setDomain,
          changeDomainModal,
          setChangeDomainModal,
          domainDetails,
          setDomainDetails,
          toDayOrder,
          setToDayOrder,
          searchName,
          setSearchName,
          solutionDetails,
          setSolutionDetails,
          planAmount,
          setPlanAmount,
          tests,
          setTests,
          planName,
          setPlanName,
          planMasterId,
          setPlanMasterId,
          planValidity,
          setPlanValidity,
        }}
      >
        {props.children}
      </QrListAllContext.Provider>
    </>
  );
};

export default QrListAllState;
