import React, { useContext, useEffect, useState } from "react";
import WhiteLabelDomainItem from "./whiteLabelDomainItem";
import WhiteLabelDomainDetailsModel from "./WhiteLabelDomainDetailsModel";
import WhiteLabelDomainAddModel from "./whiteLabelDomainAddModel";
import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import TopLoadingBar from "../topLoadingBar/topLoadingBar";
import WhiteLabelDomainContext from "../../context/WhiteLabelDomainContext/WhiteLabelDomainContext";
import LoaderContext from "../../context/LoaderContext/LoaderContext";
import {
  getWhiteLabelDomain,
  Post_create_White_Label_Domain,
  Put_edit_White_Label_Domain,
} from "../../api/WhiteLabelDomainAPi/WhiteLabelDomainApi";
import { useNavigate } from "react-router";
import SocketContext from "../../context/SocketContext/SocketContext";
import { userLogout } from "../../auth/Logout";

const WhiteLabelDomain = () => {
  const {
    setformvalue,
    setOrgID,
    logo,
    setLogo,
    favicon,
    setFavicon,
    whitelabelDomain,
    setWhiteLabelDomain,
    name,
    setName,
    url,
    setUrl,
    progress,
    setProgress,
    setModal,
    setToggle,
    editIndex,
    togglesubmit,
    seteditIndex,
    setAddBel,
    setIsHovering,
    modal,
  } = useContext(WhiteLabelDomainContext);
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const { loaderShow, setLoaderShow } = useContext(LoaderContext);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record] = useState(0);

  /**
   * Organization list get function call
   */

  const getData = async () => {
    try {
      const { data } = await getWhiteLabelDomain();
      if (data.status === true) {
        setformvalue(data.data);
      } else {
        setformvalue([]);
      }
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  useEffect(() => {
    getData();
    setProgress(100);
  }, [page, pageSize]);

  /**
   * push button click to open popup function call
   */
  const AddModel = () => {
    setModal(true);
    setToggle(true);
  };

  const emptyFeild = () => {
    setLogo("");
    setFavicon("");
    setWhiteLabelDomain("");
    setName("");
    setUrl("");
    setLoaderShow(false);
  };
  const editHandle = (id) => {
    seteditIndex(id);
    setToggle(false);
    setModal(true);
    setToggle(false);
  };

  /**
   * create and edit function call
   */

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData;

    if (editIndex !== -1 && !togglesubmit) {
      setLoaderShow(!loaderShow);
      formData = {
        whiteLabeledDomain: whitelabelDomain,
        name,
        logo,
        favicon,
        url,
      };

      try {
        const { data } = await Put_edit_White_Label_Domain(formData);
        // if (data.status === true) {
        toast.success(data.message, {
          autoClose: 1000,
        });
        setModal(false);
        getData();
        emptyFeild();
        // } else {
        // fillFeild();
        // }
      } catch (error) {
        setLoaderShow(false);
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    } else {
      setLoaderShow(!loaderShow);
      formData = {
        whiteLabeledDomain: whitelabelDomain,
        name: name,
        logo: logo,
        favicon: favicon,
        url: url,
      };

      try {
        const { data } = await Post_create_White_Label_Domain(formData);
        // if (data.status === true) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setModal(false);
        getData();
        emptyFeild();
        // } else {
        // fillFeild();
        // }
      } catch (error) {
        setLoaderShow(false);
        if (error?.response?.data?.status === 401) {
          userLogout(history, socket);
        }
        toast.error(error?.response?.data?.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
    }
  };

  /**
   * add balance function call and popup open
   */

  const AddBalance = (orgId) => {
    setOrgID(orgId);
    seteditIndex(orgId);
    setAddBel(true);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  /**
   * close model function call
   */

  const closeModel = () => {
    emptyFeild();
    setModal(!modal);
  };

  const readUrl = (event) => {
    if (event.target.name === "logo" || event.target.name === "favicon") {
      if (event.target.files.length === 0) return;
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      if (event.target.name === "logo") {
        setLogo(event.target.files[0]);
      } else if (event.target.name === "favicon") {
        setFavicon(event.target.files[0]);
      }
    }
  };

  return (
    <>
      <div>
        <TopLoadingBar progress={progress} setProgress={setProgress} />
        <WhiteLabelDomainAddModel
          onSubmit={onSubmit}
          closeModel={closeModel}
          readUrl={readUrl}
        />
        <WhiteLabelDomainDetailsModel />
        <WhiteLabelDomainItem
          handleMouseOver={handleMouseOver}
          handleMouseOut={handleMouseOut}
          AddBalance={AddBalance}
          AddModel={AddModel}
          setPage={setPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          page={page}
          record={record}
          editHandle={editHandle}
        />
      </div>
    </>
  );
};
export default WhiteLabelDomain;
