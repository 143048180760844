export const Location = "Location";

export const New = "New";

export const TodayTotalSale = "Today Total sale";

export const Name = "Name";

export const ContactUs = "Contact Us";
export const Email = "Email";

export const Phone = "Phone";
export const EmailAddress = "Email Address";

export const Password = "Password";
export const LogOut = "Log Out";

export const Pagi_Nations = "Pagination";
export const PaginationWithIcons = "Pagination with icons";
export const PaginationAlignment = "Pagination alignment";
export const PaginationActiveDisabled = "Pagination with active and disabled";
export const PaginationColor = "Pagination Color";
export const PaginationSizing = "Pagination sizing";
export const Previous = "Previous";
export const Next = "Next";

// pages
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password?";
export const CreateAccount = "Create Account";
export const AUTH0 = "AUTH0";
export const JWT = "JWT";
export const LoginWithJWT = "Login";

// layout
export const Loading = "Loading...";

export const Admin = "Admin";
export const Account = "Account";

export const IMAGE_URL =
  "https://mcode.fra1.digitaloceanspaces.com/byte-nfc/bytenfc.png";

export const DATA_NOT_FOUND =
  "https://img.freepik.com/free-vector/no-data-concept-illustration_114360-536.jpg?w=900&t=st=1667466035~exp=1667466635~hmac=fb00d8e365338a2998c49c4661d0abb17de576ce3efd265331b8a98d6877bc5b";

export const ENDPOINT_IMAGE = "/byte-nfc/bytenfc.png";

// <<<<<<<<<<<<<<<--------------USER ROLE WISE ACCESS------------------->>>>>>>>>>>>>

export const USER_ROLE = {
  ADMIN: 1,
  SELLER: 2,
};

export const FILE_TYPE = {
  WALLET: 5,
};

export const NFC_GUNRATE_STATUS = {
  CREATED: 1,
  QR_REQUESTED: 2,
  QR_GENERATED: 3,
  FAILED: 4,
};

export const QR_GUNRATE_STATUS = {
  CREATED: 1,
  QR_REQUESTED: 2,
  QR_GENERATED: 3,
  FAILED: 4,
  INACTIVE: 2,
};

export const ORDER_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
};

export const QR_STATUS = {
  QR_GENERATED: 1,
  QR_REGISTERED: 2,
};

export const ORDER_TYPE = {
  BUY: 1,
  SELL: 2,
};

export const Role = [
  {
    id: 1,
    name: "super admin",
  },
  {
    id: 2,
    name: "organization admin",
  },
];

/**
 * Button Name constant
 */

export const BUTTON = {
  SAVE: "Save",
  GENERATE_QR: "Generate QR",
  SUBMIT: "Submit",
  DOWNLOADE: "Download",
  DONE: "Done",
};

/**
 * Nfc list constant
 */

export const NFC_LIST = {
  PERENT: "Order List",
  NFCLIST: "NFC List",
  QR_SCAN: "QR Scan",
  DOWNLOADE: "Download",
  SELECT_STATUS: "Select Status",
  SAVE: "Save",
};

/**
 * Nfc Order List Constant
 */

export const NFC_ORDER_LIST = {
  PERENT: "Order List",
  EDIT_TITLE: "Edit Title",
  TITLE: "Title",
  ORDER_LIST: "Order List",
  IS_SAME: "Is Same",
  QTY: "QTY",
  CREATE_NFC: "Create NFC",
};

/**
 * Order List Constant
 */

export const ORDER_LIST = {
  PERENT: "Order List",
  GENERATE: "Generate",
  REGENERATE: "ReGenerate",
  EDIT_TITLE: "Edit Title",
  TITLE: "Customer Business Name",
  CREATE_QR: "Create QR",
  QTY: "QTY",
  PLAN_NAME: "Plan name",
  PLAN_AMOUNT: "Plan amount",
  PLAN_VALIDITY: "Plan validity in month",
  PLAN_NOTE: "Plan note",
};

/**
 * order status master constant
 */

export const ORDER_STATUS_MASTER = {
  PERENT: "Order Status Master",
  ADD_ORDER_STATUS: "Add Order Status",
  EDIT_ORDER_STATUS: "Edit Order Status",
  NAME: "Name",
};

/**
 * organization constant
 */

export const ORGANIZATION = {
  PERENT: "Organization",
  ADD_ORG: "Add Organization",
  EDIT_ORG: "Edit Organization",
  ORG_DETAILS: "Organization Details",
  ORG_NAME: "Org Name",
  WEBSITE: "Website Url",
  EMAIL: "Email",
  GST_IN: "GST In",
  DIAL_CODE: "Dial Code",
  WHATS_APP_DIAL_CODE: "WhatsApp Dial Code",
  PHONE: "Phone",
  LANDMARK: "Landmark",
  ADDRESS1: "Address 1",
  ADDRESS2: "Address 2",
  CITY: "City",
  STATE: "State",
  PINCODE: "Pin Code",
  COUNTRY: "Country",
  NOTE: "Note",
  DOMAIN: "Domain",
  QR_DOMAIN: "Qr Domain",
  SSL_FILE: "SSL File",
  ADMIN_DETAILS: "Admin Details",
  WHATS_APP_ACCESS: "Whats App Access",
  NAME: "Name",
  PASSWORD: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  CONTACT: "Contact",
  ADDRESS: "Address",
  NFCBEL: "NFC BAL",
  QRBEL: "QR BAL",
  ADD_BALANCE: "Add Balance",
  QTY: "QTY",
  TYPE: "Type",
  NFC: "NFC",
  QR: "Qr",
  PLAN_NAME: "Plan name",
  PLAN_AMOUNT: "Plan amount",
  PLAN_VALIDITY: "Plan validity in month",
  EMAILING_EMAIL: "Emailing Email",
  APP_PASSWORD: "App Password",
  SUPPORT_EMAIL: "Support Email",
  SUPPORT_PHONE: "Support Phone",
};
export const PACKAGE_TYPE = [
  { QR: "qr", value: "qr", name: "QR" },
  { MINI_WEB: "mini_web", value: "mini_web", name: "MINI WEB" },
  { MULTI_WEB: "multi_web", value: "multi_web", name: "MULTI WEB" },
  {
    AUTO_POST_MAKER: "auto_post_maker",
    value: "auto_post_maker",
    name: "AUTO POST MAKER",
  },
  { CRM: "crm", value: "crm", name: "CRM" },
  { ECOMMERCE: "ecommerce", value: "ecommerce", name: "E-COMMERCE" },
  {
    RESTAURANT_MENU: "restaurant_menu",
    value: "restaurant_menu",
    name: "RESTAURANT MENU",
  },
];

export const WHITE_LABEL_DOMAIN_CONSTANT = {
  PERENT: "White Label Domain",
  ADD_WHITE_LABEL_DOAMIN: "Add White Label Domain",
  EDIT_WHITE_LABEL_DOAMIN: "Edit White Label Domain",
  WHITE_LABEL_DOMAIN_DETAILS: "White Label Domain Details",
  WHITE_LABEL_DOMAIN: "White Label Domain",
  NAME: "Name",
  GST_IN: "GST In",
  LOGO: "Logo",
  FAVICON: "Favicon",
  URL: "Url",
};

/**
 * pachages constant
 */
export const PACKAGES = {
  PERENT: "Packages",
  ADD_PACKAGE: "Add Packages",
  EDIT_PACKAGES: "Edit Packages",
  NAME: "Name",
  PRICE: "Price",
  GST: "GST",
  QTY: "QTY",
  TYPE: "Type",
  NFC: "NFC",
  QR: "Qr",
  BENEFIT: "Benefit",
};

/**
 * org theme master constant
 */
export const ORG_THEME_MASTER = {
  PERENT: "Org Theme Access",
  ADD_PACKAGE: "Add Org Theme",
  EDIT_PACKAGES: "Edit Org Theme",
  THEME: "Theme",
};

/**
 * privillage constant
 */
export const PRIVILLAGE = {
  PERENT: "Privillage",
  ADD_PREVILLAGE: "Add Privillage",
  EDIT_PRIVILLAGE: "Edit Privillage",
  PRIVILALGE_DETAILS: "Privillage Details",
  NAME: "Name",
  METHOD: "Method",
  URLS: "URLs",
  PERENT_MODULES: "Parent Modules",
  CJILD_MODULE: "Child Module",
  MODULE_ACCESS: "Module Access",
  VIEW_PRIVILLAGE: "View Privillage",
  MODULE_NAME: "Module name",
  MODULE_ACCESSNAME: "Module AccessName",
};

/**
 * qr list constant
 */

export const QR_LIST = {
  PERENT: "QR List",
  ORDER_LIST: "OrderList",
  QR_SCAN: "QR Scan",
};

/**
 * qr list constant
 */

export const WALLET_LIST = {
  PERENT: "Wallet",
};

/**
 * seller forget password constant
 */

export const CHANGE_PASSWORD = {
  CHNAGEPASSWORD: "Change Password",
  OLD_PASSWORD: "Old Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
};

/**
 * Shipping master constant
 */

export const SHIPPING_MASTER = {
  PERENT: "Shopping Master",
  ADD_SHIPPING_MASTER: "Add Shipping Master",
  EDIT_SHIPPING_MASTER: "Edit Shipping Master",
  COMPANY_NAME: "Company Name",
  LINK: "Link",
};

/**
 * user profile constant
 */

export const USER_PROFILE = {
  PERENT: "Users",
  TITLE: "User Profile",
  EMAIL: "Email",
  NAME: "Name",
  STATE: "State",
  ORG_DETAILS: "Organization Details",
  CITY: "City",
  CONTACT_US: "Contact Us",
  LOCATION: "Location",
  ADMIN_DETAILS: "Admin Details",
};

/**
 * Users constant
 */

export const USERS = {
  PERENT: "User",
};

export const MuiTabelStyle = {
  ".MuiTablePagination-selectLabel": {
    margin: "0px",
  },
  ".MuiTablePagination-displayedRows": {
    margin: "0px",
  },

  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};

export const PLAN_TYPE = {
  QR_PLAN: 1,
  QR_SELLER: 2,
};

export const SOCKET_STATUS = {
  NOT_CONNECTED: "not_connected",
  SESSION_CLOSE: "session_closed",
  CONNECTED: "connected",
  ERROR: "error",
  DIS_CONNECTED: "disconnected",
  AUTHENTICATED: "authenticated",
  AUTH_FAILURE: "auth_failure",
  LOADING_SCREEN: "loading_screen",
  READY: "ready",
  QR: "qr",
};

export const SOCKET_EVENT = {
  CONNECT: "connect",
  STATUS: "status",
};

export const isWhatsAppPermission = "true";
