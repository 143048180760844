import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { BUTTON, CHANGE_PASSWORD, Email } from "../../constant";
import { Change_Pass } from "../../api/ChangePassAndEmailApi/ChnagePaaAndEmailApi";
import { Zoom, toast } from "react-toastify";
import { userLogout } from "../../auth/Logout";
import SocketContext from "../../context/SocketContext/SocketContext";
import { useNavigate } from "react-router";

const OrganizationChangePass = (props) => {
  const { changePssmodal, setChangePassModal } = props;
  const history = useNavigate();
  const { socket } = useContext(SocketContext);
  const [passEmail, setPassEmail] = useState("");
  const [changePassword, setChangePassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    let formData = {
      email: passEmail,
      password: changePassword,
    };
    try {
      const { data } = await Change_Pass(formData);
      if (data.status === true) {
        toast.success(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
        setPassEmail("");
        setChangePassword("");
      } else {
        toast.error(data.message, {
          autoClose: 1000,
          transition: Zoom,
        });
      }
      setChangePassModal(false);
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        userLogout(history, socket);
      }
      toast.error(error?.response?.data?.message, {
        autoClose: 1000,
        transition: Zoom,
      });
    }
  };
  return (
    <Modal
      size="lg"
      isOpen={changePssmodal}
      toggle={() => setChangePassModal(!changePssmodal)}
    >
      <ModalHeader toggle={() => setChangePassModal(!changePssmodal)}>
        {"Change Password"}
      </ModalHeader>
      <ModalBody>
        <Container fluid={true} className="p-0">
          <Row>
            <Col xs="12">
              <div className="img">
                <div>
                  <div className="login-main login-tab">
                    <Form className="theme-form" onSubmit={(e) => onSubmit(e)}>
                      <h4>{CHANGE_PASSWORD.CHNAGEPASSWORD}</h4>
                      <p>{"Enter Email and Password"}</p>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{Email}</Label>
                        <Input
                          className="form-control"
                          type={"email"}
                          onChange={(e) => setPassEmail(e.target.value)}
                          defaultValue={passEmail}
                          placeholder="Email"
                          required
                        />
                      </div>

                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{"Password"}</Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          onChange={(e) => setChangePassword(e.target.value)}
                          defaultValue={changePassword}
                          placeholder="*********"
                          required
                        />
                        <div
                          className="show-hide"
                          onClick={() => setTogglePassword(!togglePassword)}
                        >
                          <span className={togglePassword ? "" : "show"}></span>
                        </div>
                      </div>
                      <div className="login-btn mb-0">
                        <div className="ms-3">
                          <Label className="text-muted" for="checkbox1">
                            {"Please Enter Email and Password"}
                          </Label>
                        </div>
                        <Button color="primary" type="submit" value="submit">
                          {BUTTON.DONE}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default OrganizationChangePass;
